interface Node {
    id: string;
    plugin_id: string;
    config: any;
}

interface Edge {
    from_node: string;
    to_node: string;
}

interface Mapping {
    id?: string;
    name?: string;
    description?: string;
    json: {
        nodes: Node[];
        edges: Edge[];
    };
    author?: null;
}
export const parseMappingToFlow = (mapping: Mapping) => {
    const incomingConnections = new Set<string>();
    const outgoingConnections = new Set<string>();

    mapping?.json?.edges.forEach((edge) => {
        incomingConnections.add(edge?.to_node);
        outgoingConnections.add(edge?.from_node);
    });

    const leftSideNodes = mapping?.json?.nodes
        .filter((_, index) => index % 2 === 0)
        .map((node, index) => ({
            id: node.id,
            type: 'customNode',
            position: { x: 100, y: 100 + (index / 2) * 150 },
            data: {
                label: node.plugin_id,
                config: node.config,
                hasInput: incomingConnections.has(node.id),
                hasOutput: outgoingConnections.has(node.id),
            },
        }));

    const rightSideNodes = mapping?.json?.nodes
        .filter((_, index) => index % 2 !== 0)
        .map((node, index) => ({
            id: node.id,
            type: 'customNode',
            position: { x: 600, y: 100 + (index / 2) * 150 },
            data: {
                label: node.plugin_id,
                config: node.config,
                hasInput: incomingConnections.has(node.id),
                hasOutput: outgoingConnections.has(node.id),
            },
        }));

    const nodes = [...leftSideNodes, ...rightSideNodes];

    const edges = mapping.json.edges.map((edge, index) => ({
        id: `e${index}-${edge.from_node}-${edge.to_node}`,
        source: edge.from_node,
        target: edge.to_node,
        animated: true,
        type: 'default',
        markerEnd: { type: 'arrowclosed' },
    }));

    return { nodes, edges };
};

