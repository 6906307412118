import { makeStyles, shorthands } from '@fluentui/react-components'
import { useContext, useEffect, useState } from 'react'
import DashboardIcon from '../../assets/icons/nav_icons/dashboard.svg'
import { DashboardResponse, DashboardService, TimeRange } from '../../services/openapi'
import { Loading } from '../Loading'
import { PageHeader } from '../PageHeader'
import CreditUsageCard from './CreditUsageCard'
import RecentFlightsCard from './RecentFlightsCard'
import SummaryCard from './SummaryCard'
import { AuthContext } from '../../AuthContext'
import { useOrganisationCheck } from '../hooks/useOrganisationCheck'
import AskAdministrator from '../common/AskAdministrator'
import OrgInactive from '../common/OrgInactive'

export default function Dashboard() {
    const classes = useStyles()

    const [info, setInfo] = useState<DashboardResponse | null>();
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const [orgInactiveState, setOrgInactiveState] = useState(false);
    const [currentFilter, setCurrentFilter] = useState<TimeRange>()
    const { orgId } = useContext(AuthContext);
    const { noOrganisation, isOrgActive } = useOrganisationCheck();

    const dashboardResponse = (orgId: string) => {
        setLoading(true)
        DashboardService.dashboard(null, orgId)
            .then((info) => {
                setInfo(info)
                setLoading(false)
            })
            .catch((err) => {
                setError(`${err}`)
                setLoading(false)
            });
    }

    const clearFilter = () => {
        if (orgId?.orgId) {
            dashboardResponse(orgId.orgId);
        }
        setCurrentFilter(undefined)
    }

    const filterDashboardResponse = (t: TimeRange) => {
        setLoading(true)
        DashboardService.dashboard(t, orgId?.orgId).then((info) => {
            setInfo(info)
            setLoading(false)
        })
            .catch((err) => {
                setError(`${err}`)
                setLoading(false)
            });
        setCurrentFilter(t)
    }

    useEffect(() => {
        if (orgId?.orgId && isOrgActive !== null) {
            dashboardResponse(orgId.orgId);
        }
    }, [orgId, isOrgActive]);

    if (error) {
        return <div>Could not load dashboard: {error}</div>
    }

    if (loading) {
        return <Loading />;
    }

    const toggleOrgInactiveModal = (flag: boolean) => {
        setOrgInactiveState(flag);
    };

    return <>
        <PageHeader text="Dashboard" icon={DashboardIcon} />
        {
            noOrganisation && (
                <AskAdministrator />
            )
        }
        <div className={classes.container}>
            {info && (<>
                <SummaryCard info={info} currentFilter={currentFilter} />
                <div className={classes.dashboardCardsContainer}
                    onClick={() => {
                        if (!isOrgActive) {
                            setOrgInactiveState(true);
                        }
                    }}
                >
                    <div style={{
                        pointerEvents: isOrgActive ? "auto" : "none", display: "flex",
                        alignItems: "flex-start", justifyContent: "space-between"
                    }}>
                        <RecentFlightsCard isOrgInactiveState={isOrgActive} dashboardInfo={info} topOffset="24rem" filterDashboardResponse={filterDashboardResponse} currentFilter={currentFilter} clearFilter={clearFilter} />
                        <CreditUsageCard isOrgInactiveState={isOrgActive} transactions={info.transactionBuckets} topOffset="24rem" currentFilter={currentFilter} />
                    </div>
                </div>
            </>)}
            {
                orgInactiveState && <OrgInactive
                    visible={orgInactiveState}
                    toggleModal={toggleOrgInactiveModal}
                />
            }
        </div >
    </>
}

const useStyles = makeStyles({
    container: {
        ...shorthands.padding('1rem'),
    },
    dashboardCardsContainer: {
        ...shorthands.gap("1rem"),
        paddingTop: "1rem",
    },
    recentFlightsContainer: {
        height: "100%",
    }
})