/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CostEstimateRequest } from '../models/CostEstimateRequest';
import type { CostEstimateResponse } from '../models/CostEstimateResponse';
import type { InvoiceResponse } from '../models/InvoiceResponse';
import type { InvoicesResponse } from '../models/InvoicesResponse';
import type { LineItemsResponse } from '../models/LineItemsResponse';
import type { WalletInfoResponse } from '../models/WalletInfoResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class WalletService {

    /**
     * @param requestBody
     * @returns CostEstimateResponse Estimate upload credits
     * @throws ApiError
     */
    public static estimateCost(
        requestBody: CostEstimateRequest,
    ): CancelablePromise<CostEstimateResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/wallet/estimate_cost',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param orgId
     * @returns WalletInfoResponse Get wallet info
     * @throws ApiError
     */
    public static getWalletInfo(
        orgId: string,
    ): CancelablePromise<WalletInfoResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/wallet/info/',
            query: {
                'org_id': orgId,
            },
        });
    }

    /**
     * @returns InvoicesResponse Get invoices info
     * @throws ApiError
     */
    public static getInvoices(): CancelablePromise<InvoicesResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/wallet/invoices',
        });
    }

    /**
     * @param invoiceId
     * @returns InvoiceResponse Invoice
     * @throws ApiError
     */
    public static getInvoice(
        invoiceId: string,
    ): CancelablePromise<InvoiceResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/wallet/invoices/{invoice_id}',
            path: {
                'invoice_id': invoiceId,
            },
        });
    }

    /**
     * @param orgId
     * @returns LineItemsResponse Line items
     * @throws ApiError
     */
    public static getLineItems(
        orgId: string,
    ): CancelablePromise<LineItemsResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/wallet/line_items/{org_id}',
            path: {
                'org_id': orgId,
            },
        });
    }

}
