import React from 'react'
import { Button, Dialog, DialogSurface, makeStyles } from '@fluentui/react-components';
import comingSoonIcon from "../assets/icons/newOrgComingsoon.svg"
interface ModalProps {
    visible: boolean;
    toggleModal: (flag: boolean) => void;
}
function NewOrgComingsoon(props: ModalProps) {
    const { visible, toggleModal } = props;
    const classes = useStyles();

    return (
        <Dialog
            open={visible}
        >
            <DialogSurface style={{ padding: "2em 3em" }}>
                <div>
                    <h2 className={classes.heading}>New Organisation Feature Coming Soon!</h2>
                    <div className={classes.infoContainer}>
                        <p className={classes.info}>
                            We're excited to bring you the ability to add and manage multiple organisations within your account. This feature is currently under development and will be available soon. Stay tuned for updates!
                        </p>
                        <img src={comingSoonIcon} />
                    </div>
                    <Button className={classes.btn} onClick={() => { toggleModal(false) }}>Close</Button>
                </div>
            </DialogSurface>
        </Dialog >)
}

export default NewOrgComingsoon

const useStyles = makeStyles({
    heading: {
        fontSize: "1.5rem",
        fontWeight: "500",
        color: "#3E3E3E"
    },
    infoContainer: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        margin: "2em 0"
    },
    info: {
        fontSize: "1rem",
        color: "#3E3E3E",
        width: "50%"
    },
    btn: {
        width: "100%",
        background: "#007AFF",
        color: "white",
        padding: ".5em",
        fontSize: "1.1rem",
        borderRadius: "8px"
    }
})