import * as React from "react";
import { useParams } from "react-router-dom";
import { AuthService, PluginResponse, PluginsService, QueueItemResponse, TaskQueueService } from "../../services/openapi";
import { Loading } from "../Loading";
import { API_BASE } from "../../constants";
import { Dialog, DialogBody, DialogContent, DialogSurface, DialogTitle } from "@fluentui/react-components";
import { PluginTaskDetails } from "./PluginTaskDetails";

export const PluginTasks = () => {
    const { pluginId } = useParams();
    const [token, setToken] = React.useState<string | undefined>();
    const [plugin, setPlugin] = React.useState<PluginResponse | undefined>();
    const [tasks, setTasks] = React.useState<QueueItemResponse[] | undefined>();
    const [selectedTask, setSelectedTask] = React.useState<QueueItemResponse | undefined>();

    React.useEffect(() => {
        PluginsService.getOwnPlugins()
            .then(ps => {
                setPlugin(ps.plugins.find(p => p.id === pluginId));
            })
            .catch(err => console.log(err));
    }, [pluginId]);

    React.useEffect(() => {
        AuthService.getPluginTokens()
            .then(ts => {
                if (ts.tokens.length) {
                    return Promise.resolve(ts.tokens[0]);
                } else {
                    return AuthService.createPluginToken({ pluginId: plugin?.id || '' });
                }
            })
            .then(t => {
                setToken(t.token);
                getTasks();
            })
            .catch(err => console.error(err));
    }, [plugin])

    const getTasks = (noAssign: boolean = false) => {
        return fetch(`${API_BASE}/api/task_queue/items?topic=${plugin?.id}`, {
            headers: {
                Authorization: `Bearer ${token?.trim() || ''}`
            }
        })
            .then(res => {
                if (res.status === 200) {
                    return res.json();
                } else {
                    throw res;
                }
            })
            .then(ts => {
                if (ts.length || noAssign) {
                    setTasks(ts);
                } else {
                    return assignTask();
                }
            })
            .catch(err => console.error(err));
    };

    const assignTask = () => {
        return fetch(`${API_BASE}/api/task_queue/assign?topic=${plugin?.id}`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token?.trim() || ''}`
            }
        }).then(res => {
            if (res.status === 200) {
                return res.json();
            } else {
                throw res;
            }
        }).then(t => {
            if (t) {
                setTasks([...(tasks || []), t]);
            }
        })
            .catch(err => console.error(err));
    }

    const ackTask = (t: QueueItemResponse) => {
        fetch(`${API_BASE}/api/task_queue/items/${t.id}/ack`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then(res => {
            if (res.status === 200) {
                setSelectedTask(undefined);
                setTasks(undefined);
                getTasks(false);
            } else {
                throw res;
            }
        }).catch(err => console.error(err));
    };

    if (!plugin || !token) {
        return <Loading />
    }

    return <div>
        <div>Viewing tasks for {plugin.name} ({plugin.id})</div>
        <div>
            Tasks
            <button onClick={() => getTasks()}>Refresh Tasks</button>
            <button onClick={() => assignTask()}>Assign More Tasks</button>
        </div>
        {(!tasks || !tasks.length) && (<div>No tasks for this plugin</div>)}
        <div>
            <table width={100} border={1}>
                <thead>
                    <tr>
                        <th>Task created</th>
                        <th>ID</th>
                    </tr>
                </thead>
                <tbody>
                    {tasks?.map(t => {
                        return <tr key={t.id}>
                            <td>{t.created_at}</td>
                            <td>
                                <a href="#" onClick={() => setSelectedTask(t)}>{t.id}</a>
                            </td>
                        </tr>
                    })}
                </tbody>
            </table>
        </div>
        {selectedTask && (<Dialog
            open={!!selectedTask}
            onOpenChange={(_, state) => {
                if (!state.open) {
                    setSelectedTask(undefined);
                }
            }}
        >
            <DialogSurface>
                <DialogBody>
                    <DialogTitle>Task Details</DialogTitle>
                    <DialogContent>
                        <PluginTaskDetails
                            token={token || ''}
                            plugin={plugin}
                            task={selectedTask}
                            onDone={() => ackTask(selectedTask)}
                        />
                    </DialogContent>
                </DialogBody>
            </DialogSurface>
        </Dialog>)}
    </div>;
}
