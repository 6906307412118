import * as React from 'react';
import { Header } from './Header';
import { Button, Dialog, DialogBody, DialogContent, DialogSurface, DialogTitle, Dropdown, Input, Option, Toolbar, tokens } from "@fluentui/react-components";
import { useNavigate } from 'react-router-dom';
import { OrganisationResponse, OrganisationsService } from '../services/openapi';
import { BuildingBank24Regular, MultiselectLtrRegular, Notebook24Regular } from '@fluentui/react-icons';
import { AuthContext } from '../AuthContext';

export const ManageOrganisations = () => {

    const { me: user } = React.useContext(AuthContext);
    const navigate = useNavigate();
    const [organisations, setOrganisations] = React.useState<OrganisationResponse[] | null>();
    const [error, setError] = React.useState('');
    const [search, setSearch] = React.useState('');
    const [showAdd, setShowAdd] = React.useState(false);
    const [addCreditsFormValue, setAddCreditsFormValue] = React.useState<{ [id: string]: number }>({});
    const [orgError, setAddOrgError] = React.useState('');
    const [data, setData] = React.useState({ name: '', notes: '', orgType: '' });
    const [showProgress, setShowProgress] = React.useState(false);

    const valid_org_types = ["paid", "free_trial", "demo", "internal"]
    const defaultValue = '';

    //allorganisations 
    React.useEffect(() => {
        OrganisationsService.getAllOrganisations()
            .then((organisations) => setOrganisations(organisations.organisations))
            .catch((err) => setError(`Could not fetch organisations: ${err}`))
    }, [])

    const AddOrgsubmit = () => {
        OrganisationsService.addOrganisation(data)
            .then((_orgInfo: OrganisationResponse) => {
                OrganisationsService.getAllOrganisations()
                    .then((organisations) => {
                        setOrganisations(organisations.organisations)
                        setData({ ...data, name: '', notes: '', orgType: '' })
                        setShowAdd(false)
                    })
                    .catch((err) => setError(`Could not fetch organisations: ${err}`))
            })
            .catch((error) => setAddOrgError(`Could not fetch user ${error}`))
    }

    const addCredits = (orgId: string, amount: number) => {
        setShowProgress(true);
        OrganisationsService.addCredits(orgId, { amount: Math.abs(amount), add: amount > 0 })
            .then((org) => {
                const newOrgs = (organisations || []).map((o) => {
                    if (o.id === org.id) {
                        return org;
                    }
                    return o;
                });
                setOrganisations(newOrgs);
            })
            .catch((err) => {
                setError(`could not add credits: ${err}`);
            })
            .finally(() => {
                setShowProgress(false);
            });
    };

    if (!user?.admin) {
        return <div>You are not authorized to use this page.</div>
    }
    const handleOrgTypeSelect = (e: any, selectedData: any) => {
        setData({ ...data, orgType: selectedData.optionText ?? 'orgType' })
    };

    return <div style={{
        height: '96%'
    }}>
        <Header ButtonLink='/' ButtonText='Dashboard' />

        <div style={{
            display: 'flex',
            paddingRight: '3em',
            flexDirection: 'column',
            height: "95%",
            margin: '1em',
            backgroundColor: tokens.colorNeutralBackground1,
            borderRadius: '0.5em',
            boxShadow: '5px 1px 20px 0px rgba(0, 0, 0, 0.05)',
        }}>
            <div style={{
                fontWeight: 600, fontSize: '2.5em',
                margin: '1em'
            }}>
                Organisations
            </div>

            {error && <div>{error}</div>}

            <Toolbar>
                <input
                    type="input"
                    onChange={(e) => setSearch(e.target.value)}
                    placeholder="Search organisation by name/Id/notes"
                    style={{
                        width: '80%',
                        borderRadius: '1.5em',
                        padding: '1em 2em',
                        fontSize: '1.1em',
                        fontWeight: 'bold',
                        border: '0.01em solid black',
                        marginLeft: '0.5em',
                    }}
                />
                <Button style={{
                    width: "17%",
                    borderRadius: '1.5em',
                    fontSize: '1.1em',
                    paddingTop: '1em',
                    paddingBottom: '1em',
                    fontWeight: 'bold',
                    border: '0.01em solid black',
                    marginLeft: "1em",
                }}
                    onClick={() => setShowAdd(true)}>
                    Add Organisation
                </Button>
            </Toolbar>

            <br />
            <div className='scrollablelist' style={{
                overflowY: 'scroll',
                height: '100%',
            }}>
                {organisations && organisations.filter((org) =>
                    org.name.toLowerCase().includes(search) || org.id.includes(search) || org.notes?.includes(search)
                ).map((org) => {
                    const credits = addCreditsFormValue[org.id] || 0;
                    return <div
                        key={org.id}
                        style={{
                            padding: '0.8em',
                            margin: '0.8em',
                            backgroundColor: tokens.colorNeutralBackground1Hover,
                            borderRadius: '1em',
                            fontSize: '1.2em',
                        }}
                    >
                        <div>
                            <div>
                                <b>Name: </b> {org.name}<br />
                                <b>ID: </b>{org.id}<br />
                                <b>Notes: </b>{org.notes}<br />
                                <b>Credits: </b>{org.credits}<br />
                                <b>CreatedAt: </b>{org.createdAt}<br />
                                <b>UpdatedAt: </b>{org.updatedAt}<br />
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'end' }}>
                                <Button style={{ margin: '1em' }}
                                    appearance="primary"
                                    onClick={() => {
                                        navigate(`/admin/manageOrganisations/${org.id}/members`);
                                    }}
                                >
                                    View and Edit Members
                                </Button>
                                <Button style={{ margin: '1em' }}
                                    appearance="primary"
                                    onClick={() => {
                                        navigate(`/admin/manageOrganisations/${org.id}/projects`);
                                    }}
                                >
                                    View Projects
                                </Button>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                <label>Add Credits: </label>
                                <input
                                    type='number'
                                    placeholder='0.0'
                                    value={credits}
                                    onChange={(e) => {
                                        const f: any = {};
                                        f[org.id] = parseFloat(e.target.value);
                                        setAddCreditsFormValue({
                                            ...addCreditsFormValue,
                                            ...f,
                                        });
                                    }}
                                />
                                <Button
                                    style={{ margin: '1em' }}
                                    appearance="primary"
                                    disabled={credits <= 0}
                                    onClick={() => {
                                        addCredits(org.id, credits);
                                    }}
                                >
                                    Add
                                </Button>
                            </div>
                        </div>
                    </div>
                })}
            </div>
        </div >

        {showProgress && (
            <Dialog modalType='non-modal' open={showAdd} onOpenChange={(_, d) => setShowAdd(d.open)}>
                <DialogSurface style={{ width: '60%', height: '70%' }}>
                    <DialogBody style={{ width: '100%', height: '100%' }}>
                        <DialogTitle style={{ fontSize: 'unset' }}>
                            Loading...
                        </DialogTitle>
                        <DialogContent
                            style={{
                                margin: 'auto',
                                width: '60%',
                                height: '60%',
                                justifyContent: 'space-between'
                            }}>
                            Loading...
                        </DialogContent>
                    </DialogBody>
                </DialogSurface>
            </Dialog>
        )}

        {showAdd ?
            <Dialog modalType='non-modal' open={showAdd} onOpenChange={(_, d) => setShowAdd(d.open)}>
                <DialogSurface style={{ width: '60%', height: '70%' }}>
                    <DialogBody style={{ width: '100%', height: '100%' }}>
                        <DialogTitle style={{ fontSize: 'unset' }}>
                            Add Organisation
                        </DialogTitle>
                        <DialogContent
                            style={{
                                margin: 'auto',
                                width: '60%',
                                height: '70%',
                                justifyContent: 'space-between'
                            }}>
                            <div style={{ paddingBottom: '2em' }}>
                                <Input
                                    style={{
                                        width: '100%',
                                    }}
                                    appearance="outline"
                                    size="large"
                                    placeholder="Name"
                                    contentBefore={<BuildingBank24Regular />}
                                    value={data.name}
                                    onChange={(e) => {
                                        setData({ ...data, name: e.target.value })
                                    }}
                                />
                            </div>
                            <div style={{
                                display: 'flex',
                                alignItems: 'center',
                                width: '100%', marginBottom: '2em', padding: ".3em .5em", border: "1px solid #c0bfbf", borderRadius: "4px", borderBottom: "1px solid black"
                            }}>
                                <MultiselectLtrRegular style={{ fontSize: "1.2rem" }} />
                                <Dropdown style={{ border: "none", width: "100%" }}
                                    placeholder='Org Type'
                                    onOptionSelect={(e, data) => handleOrgTypeSelect(e, data)}
                                    defaultSelectedOptions={[defaultValue]}
                                    defaultValue={defaultValue}
                                >
                                    {valid_org_types.map((ele) => (
                                        <Option key={ele} value={ele}>
                                            {ele}
                                        </Option>
                                    ))}
                                </Dropdown>
                            </div>
                            <div style={{ paddingBottom: '2em' }}>
                                <Input
                                    style={{
                                        width: '100%',
                                    }}
                                    appearance="outline"
                                    size="large"
                                    placeholder="Notes"
                                    contentBefore={<Notebook24Regular />}
                                    value={data.notes}
                                    onChange={(e) => {
                                        setData({ ...data, notes: e.target.value })
                                    }}
                                />
                            </div>

                            <Button
                                style={{
                                    width: '100%',
                                    backgroundColor: 'red'
                                }}
                                appearance="primary"
                                onClick={() => {
                                    AddOrgsubmit();

                                }}
                                disabled={!data.name}
                            >
                                Add
                            </Button>

                            <div style={{ color: 'red', fontWeight: 600, fontSize: '1em', marginLeft: '2em', marginTop: '2em' }}>
                                {!orgError ? "" : orgError}
                            </div>
                        </DialogContent>
                    </DialogBody>
                </DialogSurface>
            </Dialog>
            : <></>
        }
    </div>
}