import { useState, useEffect, useContext } from 'react';
import { OrganisationsService } from '../../services/openapi';
import { AuthContext } from '../../AuthContext';

export const useOrganisationCheck = () => {
    const [noOrganisation, setNoOrganisation] = useState(false);
    const [isOrgActive, setIsOrgActive] = useState<boolean | null>(null);
    const [isOrgDemo, setISOrgDemo] = useState<boolean | null>(null);
    const { orgId } = useContext(AuthContext);

    useEffect(() => {
        const getUserOrganisation = () => {
            OrganisationsService.getMyOrganisations()
                .then((data) => {
                    const organisations = data.organisations;
                    const hasOrganisations = organisations.length > 0;
                    setNoOrganisation(!hasOrganisations);

                    if (hasOrganisations && orgId?.orgId) {
                        const selectedOrg = organisations.find(org => org.id === orgId.orgId);
                        if (selectedOrg) {
                            setIsOrgActive(selectedOrg.active);
                            if (selectedOrg.orgType === "demo") {
                                OrganisationsService.getMyRoles()
                                    .then((roles) => {
                                        const userRole = roles.roles.find(role => role.organisationId === orgId.orgId && role.role === 'viewer');
                                        setISOrgDemo(!!userRole);
                                    })
                                    .catch(() => {
                                        setISOrgDemo(null);
                                    });
                            } else {
                                setISOrgDemo(false);
                            }
                        } else {
                            setIsOrgActive(null);
                            setISOrgDemo(null);
                        }
                    } else if (!orgId?.orgId) {
                        setIsOrgActive(null);
                        setISOrgDemo(null);
                    }
                })
                .catch(() => {
                    setNoOrganisation(true);
                    setIsOrgActive(null);
                    setISOrgDemo(null);
                });
        };

        if (orgId?.orgId || orgId?.orgId === undefined) {
            getUserOrganisation();
        }
    }, [orgId]);



    return { noOrganisation, isOrgActive, isOrgDemo };
};
