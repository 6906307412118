import React, { useMemo } from 'react';
import { Handle, Position } from 'reactflow';
import { makeStyles } from '@fluentui/react-components';
import { truncateText } from '../common/textUtils'

interface CustomNodeProps {
    data: any;
}

const CustomNode = ({ data }: CustomNodeProps) => {
    const classes = useStyles();
    const handleStyle = {
        background: '#555',
        width: '12px',
        height: '12px',
        borderRadius: 0,
    };
    const nodeHeight = useMemo(() => {
        const inputsCount = data?.inputs?.length || 0;
        const outputsCount = data?.outputs?.length || 0;
        const portHeight = 30; // Each port takes 30px
        const baseHeight = 60; // Increased base height for better spacing

        return baseHeight + Math.max(inputsCount, outputsCount) * portHeight;
    }, [data]);


    return (
        <div className={data.isSelected ? classes.activeNode : classes.inActiveNode}
            style={{
                padding: '10px',
                minHeight: `${nodeHeight}px`,
                minWidth: "500px",
                borderRadius: '8px',
                position: 'relative',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            {/* Render multiple input ports */}
            {data?.inputs?.map((input: any, index: number) => (
                <div key={index}>
                    <React.Fragment key={`output-${index}`}>
                        <Handle
                            type="target"
                            position={Position.Left}
                            id={`input-${input.id}`}
                            style={{ ...handleStyle, top: `${(index + 1) * 30}px`, backgroundColor: "#7D8FA9", border: "1px solid #E3E9F2" }}  // Adjusting vertical position for each input port
                        />
                        <div style={{ position: 'absolute', left: '10px', top: `${(index + 1) * 30 - 10}px`, fontSize: '.8rem' }}>
                            {input.id}
                        </div>
                    </React.Fragment>
                </div>
            ))}

            {/* Label and Dropdown */}
            <div style={{ fontWeight: 'bold', width: "100%", textAlign: 'center' }}>
                <p>{truncateText(data.name, 5)}</p>
                <p style={{ fontWeight: "400" }}>({truncateText(data.nodeId, 5)})</p>
            </div>

            {/* Render multiple output ports */}

            {
                data?.outputs?.map((output: any, index: number) => (
                    <div key={index}>
                        <React.Fragment key={`output-${index}`}>
                            <Handle
                                type="source"
                                position={Position.Right}
                                id={`output-${output.id}`}
                                style={{ ...handleStyle, top: `${(index + 1) * 30}px`, backgroundColor: "#7D8FA9", border: "1px solid #E3E9F2" }}  // Adjusting vertical position for each output port
                            />
                            <div style={{ position: 'absolute', right: '10px', top: `${(index + 1) * 30 - 10}px`, fontSize: '.8rem', textAlign: 'right' }}>
                                {output.id}
                            </div>
                        </React.Fragment>
                    </div>
                ))
            }
        </div >
    );
};

export default CustomNode;

const useStyles = makeStyles({
    activeNode: {
        background: "rgb(214 227 250)",
        color: "#4D4D4D",
        border: "1px solid #7D8FA9",
        boxShadow: "5px 1px 64px -8px rgba(214 227 250,0.48)"
    },
    inActiveNode: {
        background: "rgb(245 245 245)",
        border: "1px solid #7D8FA9"
    }
})