import * as React from "react";
import { PluginResponse, PluginsService, UserResponse } from "../../services/openapi";
import { Loading } from "../Loading";
import { Link } from "react-router-dom";

export const PluginsLibrary = ({ me }: { me: UserResponse }) => {
    const [error, setError] = React.useState('');
    const [plugins, setPlugins] = React.useState<PluginResponse[] | undefined>();

    React.useEffect(() => {
        PluginsService.getOwnPlugins()
            .then(plugins => setPlugins(plugins.plugins))
            .catch(err => setError(`Could not load plugins: ${err}`));
    }, []);

    if (!plugins) {
        return <Loading />
    }

    if (error) {
        return <div>{error}</div>
    }

    return <div>
        <div>Plugins Library</div>
        <div>
            <Link to={'/plugins/new'}>Create new plugin</Link>
        </div>
        <table>
            <thead>
                <tr>
                    <th>ID</th>
                    <th>Name</th>
                    <th></th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                {plugins
                    // .filter(p => !!p.publisher)
                    .map(p => {
                        return <tr key={p.id}>
                            <td>{p.id}</td>
                            <td>{p.name}</td>
                            <td><Link to={`/plugins/${p.id}/edit`}>Edit</Link></td>
                            <td>{p.publisher === me.userId && (<Link to={`/plugins/${p.id}/tasks`}>View Tasks</Link>)}</td>
                        </tr>;
                    })}
            </tbody>
        </table>
    </div>
};
