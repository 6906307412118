import React from "react";
import { Route, Routes } from "react-router-dom";
import { AuthContext } from "../AuthContext";
import usePageView from "../hooks/usePageView";
import { AddFeatureDemo } from "./AddFeatureDemo";
import { AddUser } from "./AddUser";
import { ComingSoon } from "./ComingSoon";
import { Flight } from "./Flight";
import LoginPage from "./LoginPage";
import { ManageOrganisations } from "./ManageOrganisations";
import { ManageUsers } from "./ManageUsers";
import MapsDemoPage from "./MapsDemoPage";
import { NewFlightForm } from "./NewFlightForm";
import { OrganisationMembers } from "./OrganisationMembers";
import { OrganisationProjects } from "./OrganisationProjects";
import { PageNotFound } from "./PageNotFound";
import { PluginTokensList } from "./PluginTokensList";
import { ProjectViews } from "./ProjectViews";
import { SharedMapViewer } from "./SharedMapViewer";
import { Transactions } from "./Transactions";
import { User } from "./User";
import { WorkflowWrapper } from "./Workflow";
import Eula from "./auth/Eula";
import PrivacyPolicy from "./auth/PrivacyPolicy";
import Dashboard from "./dashboard/Dashboard";
import AllWorkFlows from "./discover_apps/AllWorkFlows";
import DMSReport from "./discover_apps/DMSReport";
import DiscoverApps from "./discover_apps/DiscoverApps";
import RecommendedWorkflows from "./discover_apps/RecommendedWorkflows";
import { GCPTaggingPage } from "./gcp_tagging";
import MobileBecomeProvider from "./landing/common/MobileBecomeProvider";
import MobileBookDemo from "./landing/common/MobileBookDemo";
import AboutUs from "./landing/pages/about-us/AboutUs";
import BecomeaProvider from "./landing/pages/become-provider/BecomeaProvider";
import FAQs from "./landing/pages/faqs/FAQs";
import { HomeWrapper } from "./landing/pages/home/HomeWrapper";
import Solutions from "./landing/pages/solutions/Solutions";
import { PluginEditor } from "./plugins_library/PluginEditor";
import { PluginsLibrary } from "./plugins_library/PluginsLibrary";
import { NewProjectForm } from "./projects/NewProjectForm";
import { Project } from "./projects/Project";
import { ProjectsDashboard } from "./projects/ProjectsDashboard";
import RaiseQuery from "./support/RaiseQuery";
import Support from "./support/Support";
import SupportFaqs from "./support/SupportFaqs";
import { TechnicalDocumentation } from "./support/TechnicalDocumentation";
import DefaultWorkFlow from "./workflow_library/DefaultWorkFlow";
import MyWorkFlow from "./workflow_library/MyWorkFlow";
import WorkFlowLibrary from "./workflow_library/WorkFlowLibrary";
import { NewTemplate } from "./workflow_templates/NewTemplate";
import { TemplatesList } from "./workflow_templates/TemplatesList";
import WorkflowDesign from "./workflow_design/WorkflowDesign";
import WorkflowTemplates from "./workflow_design_graphs/WorkflowTemplates";
import { PluginTasks } from "./plugins_library/PluginTasks";
import { useOrganisationCheck } from "./hooks/useOrganisationCheck";

export const AppRoutes = () => {
    const { me } = React.useContext(AuthContext);
    const { isOrgDemo } = useOrganisationCheck();
    usePageView();

    return <>
        <Routes>
            <Route
                path="/"
                element={
                    <HomeWrapper />
                }
            />
            <Route
                path="/login"
                element={
                    <LoginPage />
                }
            />
            <Route
                path="/book-demo"
                element={
                    <MobileBookDemo />
                }
            />
            <Route
                path="/mob-becomeProvider"
                element={
                    <MobileBecomeProvider />
                }
            />

            <Route
                path="/solutions"
                element={
                    <Solutions />
                }
            />
            <Route
                path="/faqs"
                element={
                    <FAQs />
                }
            />
            <Route
                path="/become-Provider"
                element={
                    <BecomeaProvider />
                }
            />
            <Route
                path="/about-us"
                element={
                    <AboutUs />
                }
            />
            <Route
                path="/dashboard"
                element={
                    <Dashboard />
                }
            />
            <Route
                path="/marketplace"
                element={
                    <ComingSoon />
                }
            />
            {(me?.pluginClient || me?.admin) && (
                <>
                    <Route
                        path="/plugins"
                        element={
                            <PluginsLibrary me={me} />
                        }
                    />
                    <Route
                        path="/plugins/new"
                        element={
                            <PluginEditor create edit />
                        }
                    />
                    <Route
                        path="/plugins/:pluginId"
                        element={
                            <PluginEditor />
                        }
                    />
                    <Route
                        path="/plugins/:pluginId/edit"
                        element={
                            <PluginEditor edit />
                        }
                    />
                    <Route
                        path="/plugins/:pluginId/tasks"
                        element={
                            <PluginTasks />
                        }
                    />
                    <Route
                        path="/workflow_design"
                        element={
                            <WorkflowDesign />
                        }
                    />


                    <Route
                        path="/workflow_design_graph"
                        element={
                            <WorkflowTemplates />
                        }
                    />
                </>
            )}
            <Route
                path="/workflows"
                element={
                    <TemplatesList />
                }
            />
            <Route
                path="/workflows/new-workflow"
                element={
                    <NewTemplate />
                }
            />
            <Route
                path="/workflows/edit-workflow"
                element={
                    <NewTemplate edit />
                }
            />
            <Route
                path="/projects"
                element={
                    <ProjectsDashboard />
                }
            />
            <Route
                path="/support"
                element={
                    <Support />
                }
            />
            <Route
                path="/support/faqs"
                element={
                    <SupportFaqs />
                }
            />
            <Route
                path="/support/raiseaquery"
                element={
                    <RaiseQuery />
                }
            />
            <Route
                path="/new-project"
                element={
                    <NewProjectForm />
                }
            />
            <Route
                path="/projects/:projectId"
                element={
                    <Project />
                }
            />
            <Route
                path="/projects/:projectId/new-flight"
                element={
                    <NewFlightForm />
                }
            />
            <Route
                path="/projects/:projectId/views"
                element={
                    <ProjectViews />
                }
            />
            <Route
                path="/projects/:projectId/flights/:flightId"
                element={
                    <Flight />
                }
            />
            <Route
                path="/workflows/:workflowId"
                element={
                    <WorkflowWrapper />
                }
            />

            <Route
                path="/workflows/:workflowId/gcptagging"
                element={
                    <GCPTaggingPage />
                }
            />
            {!isOrgDemo && (
                <Route path="/transactions"
                    element={<Transactions />
                    }
                />
            )}
            <Route path="/pluginTokens"
                element={<PluginTokensList />
                }
            />
            <Route
                path="/faq"
                element={
                    <ComingSoon />
                }
            />
            <Route
                path="/guide"
                element={
                    <ComingSoon />
                }
            />
            <Route
                path="/discover-apps"
                element={
                    <DiscoverApps />
                }
            />
            <Route
                path="/discover-apps/recommended-Workflows"
                element={
                    <RecommendedWorkflows />
                }
            />
            <Route
                path="/discover-apps/workflow-details/:dsrID"
                element={
                    <DMSReport />
                }
            />
            <Route
                path="/discover-apps/allWorkFlows"
                element={
                    <AllWorkFlows />
                }
            />
            <Route
                path="/workFlow-library"
                element={
                    <WorkFlowLibrary />
                }
            />
            <Route
                path="/workFlow-library/default-workFlowLibrary/:workFlowId"
                element={
                    <DefaultWorkFlow />
                }
            />
            <Route
                path="/workFlow-library/my-workFlowLibrary"
                element={
                    <MyWorkFlow />
                }
            />
            <Route
                path="/eula"
                element={
                    <Eula />
                }
            />
            <Route
                path="/privacy-policy"
                element={
                    <PrivacyPolicy />
                }
            />
            <Route
                path="/support/docs"
                element={
                    <TechnicalDocumentation />
                }
            />




            <Route
                path="/workflow_design"
                element={
                    <WorkflowDesign />
                }
            />

            <Route
                path="/workflow_design_graph"
                element={
                    <WorkflowTemplates />
                }
            />


            <Route
                path="/share/:shareID"
                element={
                    <SharedMapViewer />
                }
            />

            <Route
                path="/maps-demo"
                element={
                    <MapsDemoPage />
                }
            />

            {me?.admin && (
                <Route
                    path="/admin/manageUsers"
                    element={
                        <ManageUsers />
                    }
                />
            )}

            {me?.admin && (
                <Route
                    path="/admin/manageUsers/:userId"
                    element={
                        <User />
                    }
                />
            )}
            {me?.admin && (
                <Route
                    path="/admin/manageUsers/addUser"
                    element={
                        <AddUser />
                    }
                />
            )}
            {me?.admin && (
                <Route
                    path="/admin/manageOrganisations"
                    element={
                        <ManageOrganisations />
                    }
                />
            )}
            {me?.admin && (
                <Route
                    path="/admin/manageOrganisations/:orgId/members"
                    element={
                        <OrganisationMembers />
                    }
                />
            )}
            {me?.admin && (
                <Route
                    path="/admin/manageOrganisations/:orgId/projects"
                    element={
                        <OrganisationProjects />
                    }
                />
            )}

            {me?.admin && (
                <Route
                    path="/admin/feature-demos"
                    element={
                        <AddFeatureDemo />
                    }
                />
            )}
            <Route
                path="*"
                element={
                    <PageNotFound />
                }
            />
        </Routes>
    </>;
};
