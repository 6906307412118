import React from 'react';
import { Button } from '@fluentui/react-components';
import Form from "@rjsf/fluentui-rc";
import validator from "@rjsf/validator-ajv8";
import { WorkFlowContext } from './WorkflowContext';

function AddConfig() {
    const { configData, setConfigData, setIsConfigSaved } = React.useContext(WorkFlowContext);
    const [formValues, setFormValues] = React.useState<Record<string, any>>({});

    const handleSubmit = (ev: React.FormEvent) => {
        ev.preventDefault();
        const updatedConfidData = configData.map((nodeSchema) => {
            const newDefaults = formValues[nodeSchema.nodeId] || {};
            const updatedSchema = {
                ...nodeSchema,
                schema: {
                    ...nodeSchema.schema,
                    properties: {
                        ...nodeSchema.schema.properties,
                        ...Object.keys(nodeSchema.schema.properties).reduce((acc, key) => {
                            const property = nodeSchema.schema.properties[key];
                            acc[key] = {
                                ...property,
                                default: newDefaults[key] !== undefined ? newDefaults[key] : property.default
                            };
                            return acc;
                        }, {} as Record<string, any>)
                    }
                }
            };
            return updatedSchema;
        });

        setConfigData(updatedConfidData);
        setIsConfigSaved(true)
    };

    return (
        <div style={{ padding: "0 .5em" }}>
            <h2 style={{ fontSize: "1.2rem" }}>Plugin Configuration</h2>
            <div>
                {
                    (configData && configData.length > 0) && configData[0]?.nodeName &&
                    <p style={{ margin: "1em 0" }}> {configData[0]?.nodeName}</p>
                }
            </div>
            {
                (configData && configData.length > 0) && Object.keys(configData[0]?.schema).length > 0 ?
                    <div>
                        <p>Config</p>
                        {configData.map((nodeSchema, idx) => (
                            <div key={idx}>
                                <h2 style={{ margin: "1em 0" }}>{nodeSchema.nodeId}</h2>
                                <div>
                                    <Form
                                        formData={formValues[nodeSchema.nodeId] || {}}
                                        schema={nodeSchema.schema}
                                        validator={validator}
                                        onChange={(e) => {
                                            const nodeForm = {} as any;
                                            if (e.formData) {
                                                nodeForm[nodeSchema.nodeId] = e.formData;
                                            }
                                            setFormValues(prev => ({
                                                ...prev,
                                                ...nodeForm,
                                            }));
                                        }}
                                        uiSchema={uiSchema}
                                    />
                                    <Button appearance="primary" onClick={handleSubmit}> Save</Button>
                                </div>
                            </div>
                        ))}
                    </div>
                    : <div style={{ textAlign: "center", marginTop: "3em", fontWeight: "500" }}>Not available</div>
            }
        </div >
    );
}

export default AddConfig;

const uiSchema = {
    "ui:submitButtonOptions": {
        norender: true
    },
    "file_type": {
        "ui:description": " "
    }
}