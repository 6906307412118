import React from 'react'
import { makeStyles } from '@fluentui/react-components'
import stockpileVolume from "../../../../../assets/icons/landing/stockpileVolume.jpg"
import countourMapping from "../../../../../assets/icons/landing/countourMap.jpg"
import ssm from "../../../../../assets/icons/landing/ssm.jpg"
import topoMap from "../../../../../assets/icons/landing/topoMap.jpg"

function Usecases() {

    const classes = useStyles();
    return (
        <div className={classes.container}>
            <h1 className={classes.heading}>Use Cases</h1>
            <div className={classes.evenRow}>
                <img src={stockpileVolume} alt='stockpileVolume' />
                <div className={classes.more}>
                    <h2>Stockpile Volume Estimation</h2>
                    <p>Optimize resources, maintain accurate inventories, enhance operational efficiency, and ensure material quality.</p>
                    <p> Resource Management</p>
                    <p> Inventory Control</p>
                    <p> Operational Efficiency</p>
                    <p> Quality Control</p>
                </div>
            </div>
            <div className={classes.evenRow}>
                <div>
                    <h2>Contour Mapping</h2>
                    <p>Ensure effective planning and improvement for the operations with reliable and detailed contour maps. Our solution supports a range of applications from site planning to resource management.</p>
                    <p>Customizable contour intervals</p>
                    <p> Inventory Control</p>
                    <p> Integrated GIS Data</p>
                    <p> Cloud-based access:</p>
                </div>
                <img src={countourMapping} alt='countourMapping' />
            </div>
            <div className={classes.evenRow}>
                <img src={ssm} alt='ssm' />
                <div className={classes.more}>
                    <h2>Stockpile Spotlevel Map</h2>
                    <p> Keep a close watch on your stockpile inventory ensuring optimal resource management and operational efficiency.</p>
                    <p> High Accuracy</p>
                    <p> Automated information processing</p>
                    <p> Real-time tracking</p>
                    <p> Advanced reporting</p>
                </div>
            </div>
            <div className={classes.evenRow}>
                <div>
                    <h2>Topo Map</h2>
                    <p>Plan efficient drilling and blasting, identify potential hazards, and optimize machinery placement and transport routes.</p>
                    <p>Excavation Planning</p>
                    <p> Safety</p>
                    <p> Logistics</p>
                </div>
                <img src={topoMap} alt='topoMap' />
            </div>
        </div>
    )
}

export default Usecases

const useStyles = makeStyles({
    "container": {
        width: "70%",
        margin: "0 auto",
        padding: 0,
        position: "relative",
    },
    heading: {
        textAlign: "center",
        fontSize: "2.5rem",
        paddingTop: "1.2em",
        paddingBottom: "1em",
        fontWeight: "bold"
    },
    more: {
        marginLeft: "1em"
    },
    evenRow: {
        display: "flex",
        justifyContent: "space-between",
        height: "35vh",
        marginBottom: "2em",
        ">div": {
            width: "50%"
        },
        ">img": {
            width: "50%"
        },

        ">div >h2": {
            fontSize: "1.5rem",
            fontWeight: "500",
            marginBottom: ".5em"
        },
        ">div >p": {
            width: "90%",
            fontSize: "1rem",
            fontWeight: "400",
            color: "#686876",
            marginBottom: ".5em"
        }
    },
})