import * as React from 'react'
import { Button, makeStyles } from '@fluentui/react-components';
import ellipsIcon from "../../../../../assets/icons/landing/ellipse.svg"
import fcmdIcon from "../../../../../assets/icons/landing/FCDM.jpg"
import BrandName from '../../../../common/BrandName';
import miningLand from "../../../../../assets/icons/landing/miningLand.svg"
import quickDeployIcon from "../../../../../assets/icons/landing/quickDeployment.svg";
import resultInIcon from "../../../../../assets/icons/landing/resultIn.svg";
import lowestTcoIcon from '../../../../../assets/icons/landing/lowestTCO.svg';
import KeyFeatures from './KeyFeatures';
import Limitations from './Limitations';
import Lottie from 'react-lottie';
import deedDive from "../../../../../assets/icons/landing/deepDive.jpg"
import BookDemoModal from '../../../common/BookDemoModal';
import Usecases from './Usecases';
import { ArrowLeftRegular } from '@fluentui/react-icons';

interface MiningLottieProps {
    options: any;
    isLoading: boolean;
    onLottieLoaded: () => void;
    toggleMining: (flag: boolean) => void;
}

function MiningSolution({ options, toggleMining }: MiningLottieProps) {
    const classes = useStyles();
    const [bookDemoModalVisible, setBookDemoModalVisible] = React.useState(false);

    const toggleBookDemoModal = (flag: boolean) => {
        setBookDemoModalVisible(flag)
    }

    return (
        <div className={classes.container}>
            <div className={classes['header-container']}>
                <ArrowLeftRegular onClick={() => { toggleMining(false) }} style={{ position: "absolute", cursor: "pointer", fontSize: "2rem", left: "150px", top: "35px" }} />
                <div className={classes['header']}>
                    <img src={fcmdIcon} alt='fcmdIcon' />
                </div>
                <img src={miningLand} alt='miningLand' className={classes.miningLand} />
                <Button shape="circular" onClick={() => { toggleBookDemoModal(true) }} style={{
                    padding: ".8em 1em", background: "#1482DA", color: "white", position: "absolute", bottom: "85px",
                    left: "17%"
                }}
                >Contact to Expert</Button>
            </div>
            <div className={classes.headerInfo}>
                <div className={classes.infoContainer}>
                    <h2 className={classes.firstLabel}>Next-Generation</h2>
                    <h1 className={classes.secondLabel}>Mining</h1>
                    <h3 className={classes.thirdLabel}><BrandName /> and DeepMatrix</h3>
                    <p className={classes.infoLabel}>Streamline Your Drone Data Management with <br /> Our Custom Cloud-Based Automated Solution</p>
                </div>
            </div>

            <div className={classes['result-container']}>
                <div className={classes.overviewContainer}>
                    <img style={{ marginLeft: "8em" }} className={classes.overviewImg} src={quickDeployIcon} alt='quickDeployIcon' />
                    <div style={{ display: "flex", fontWeight: "500", width: "20vw", fontSize: "1.3vw" }}>
                        <p>------------------------------------</p>
                    </div>
                    <img className={classes.overviewImg} src={resultInIcon} alt='resultInIcon' />
                    <div style={{ display: "flex", fontWeight: "500", width: "20vw", fontSize: "1.3vw" }}>
                        <p>------------------------------------</p>
                    </div>
                    <img style={{ marginRight: "8em" }} className={classes.overviewImg} src={lowestTcoIcon} alt='lowestTcoIcon' />
                </div>
                <div className={classes.overviewContainer}>
                    <h2 className={classes.overviewLabel}>Enables quick deployment of custom cloud-based automated workflow</h2>
                    <h2 className={classes.overviewLabel}>Result In</h2>
                    <h2 className={classes.overviewLabel}>Lowest TCO for drone-based GIS analytics</h2>
                </div>
            </div>
            <div className={classes['kf-container']}>
                <KeyFeatures />
            </div>
            <div className={classes['limitation-container']}>
                <Limitations />
            </div>
            <div>
                <h2 className={classes.heading} style={{ color: "#1482DA" }}>How it Works</h2>
                <div style={{ width: "70%", margin: "0 auto" }}>
                    <Lottie
                        options={options}
                        height={"80%"}
                        width={"100%"}
                    />
                    <div className={classes.worksContainer}>
                        <p><span>Seamless Integration:</span> Get to experience a smooth, integrated workflow from data collection to analysis.</p>
                        <p><span>Efficiency and Accuracy:</span> Benefit from automated solutions that enhance productivity while also maintaining high accuracy standards.</p>
                        <p><span>Cost-Effective:</span> Reduce operational costs by streamlining workflows along with eliminating the need for multiple vendors.</p>
                    </div>
                </div>
            </div>
            <div className={classes['takeFlyght-container']} style={{ marginTop: "3em" }}>
                <h2 style={{ fontSize: "2.5rem", fontWeight: "bold", padding: "1em 0" }}>Ready to take flight?</h2>
                <h3 style={{ fontSize: "1.2rem", fontWeight: "500" }}>Sign in to your FlyghtCloud account or book a demo to experience the <br /> future of drone data analytics firsthand.</h3>
                <Button shape='circular' style={{ color: "black", margin: "1.5em 0", padding: ".7em 2.5em" }} onClick={() => { toggleBookDemoModal(true) }}>Contact Expert</Button>
            </div>
            <div className={classes['usecase-container']}>
                <Usecases />
            </div>
            <div className={classes['takeFlyght-container']} style={{ background: "#0A2C3D", textAlign: "start" }}>
                <div style={{
                    width: "70%",
                    margin: "0 auto",
                    display: "flex",
                    justifyContent: "space-between"
                }}>
                    <div style={{ width: "50%", position: "relative" }}>
                        <img src={deedDive} alt='deedDive' style={{ position: "absolute", height: "110%", bottom: "-.7rem" }} />
                    </div>
                    <div style={{ width: "50%" }}>
                        <h2 style={{ fontSize: "2.5rem", fontWeight: "bold", padding: "1em 0" }}>Mining Workflow Deepdive</h2>
                        <h3 style={{ fontSize: "1rem", fontWeight: "400", lineHeight: "1.5rem" }}>Unlock the full potential of your mining operations with FlyghtCloud and DeepMatrix. Collaborate with us to achieve more efficiency and accuracy for your mining initiatives</h3>
                        <Button shape='circular' style={{ color: "black", margin: "1.5em 0", padding: ".7em 2.5em" }}
                            onClick={() => { window.open('https://ideaforgetech.com/contactus', '_blank') }}
                        >Register now</Button>
                    </div>
                </div>
            </div>
            <div className={classes['takeFlyght-container']} style={{ marginTop: "8em", marginBottom: 0 }}>
                <h2 style={{ fontSize: "2.5rem", fontWeight: "bold", padding: "1em 0" }}>Ready to take flight?</h2>
                <h3 style={{ fontSize: "1.2rem", fontWeight: "500" }}>Sign in to your FlyghtCloud account or book a demo to experience the <br /> future of drone data analytics firsthand.</h3>
                <Button shape='circular' style={{ color: "black", margin: "1.5em 0", padding: ".7em 2.5em" }} onClick={() => { toggleBookDemoModal(true) }}>Contact Expert</Button>
            </div>
            <BookDemoModal visible={bookDemoModalVisible} toggleModal={toggleBookDemoModal} />
        </div >
    )
}

export default MiningSolution;

const useStyles = makeStyles({
    "container": {
        width: "100%",
        margin: 0,
        padding: 0,
        position: "relative",
    },
    "header-container": {
        height: "80vh",
        backgroundImage: `url(${ellipsIcon})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "1100px 1000px",
        backgroundPosition: "right",
        backgroundPositionX: "600px",
        backgroundPositionY: "90%",
        position: "relative",
        zIndex: 99
    },
    "header": {
        width: "65%",
        margin: "0 auto",
        paddingTop: "1em",
        ">img": {
            width: "30%"
        }
    },
    "headerInfo": {
        background: "rgb(240 248 255)",
        height: "63vh",
        top: "3%",
        zIndex: 80,
        width: "100%",
        position: "absolute",
    },
    "result-container": {
        height: "30vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
    },
    "kf-container": {
        height: "60vh",
    },
    "limitation-container": {
        height: "70vh",
        background: "#2B2F471A"
    },
    "takeFlyght-container": {
        margin: "2em 0",
        background: "#1482DA",
        color: "white",
        position: "relative",
        display: "flex",
        textAlign: "center",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center"
    },
    "usecase-container": {},
    infoContainer: {
        width: "65%",
        margin: "0 auto",
        paddingTop: "4em",
        paddingBottom: "8em"
    },
    firstLabel: {
        fontSize: "1.5rem",
        fontWeight: "500",
        lineHeight: "1.2rem"
    },
    secondLabel: {
        fontSize: "6rem",
        fontWeight: "500",
        lineHeight: "4rem",
        padding: ".3em 0"
    },
    thirdLabel: {
        fontSize: "1.7rem",
        fontWeight: "600",
        lineHeight: "1.2rem",
        padding: ".5em 0"
    },
    infoLabel: {
        fontSize: "1rem",
        fontWeight: "500",
        lineHeight: "1.2rem",
        padding: ".5em 0"
    },
    miningLand: {
        width: "40%",
        zIndex: 999,
        position: "absolute",
        right: "15%",
    },
    overviewContainer: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        width: "75%",
        padding: 0,
        margin: "0 auto"
    },
    overviewImg: {
        margin: 0,
        width: "10%",
        objectFit: "cover",
        height: "10vh"
    },
    overviewLabel: {
        width: "30%",
        fontSize: "1.2rem",
        textAlign: "center"
    },
    heading: {
        textAlign: "center",
        fontSize: "2.5rem",
        paddingTop: "1.5em",
        paddingBottom: ".5em",
        fontWeight: "bold"
    },
    worksContainer: {
        border: "1px solid #707070",
        borderRadius: "13px",
        padding: "1.5em 3em",
        ">p": {
            margin: ".3em 0",
            fontWeight: "500"
        },
        ">p >span": {
            fontWeight: "700"
        }
    }
})