import { Button, Dialog, DialogActions, DialogBody, DialogContent, DialogSurface, DialogTitle, DialogTrigger } from '@fluentui/react-components';
import React from 'react'

interface ModalProps {
    visible: boolean;
    toggleModal: (flag: boolean) => void;
}
function OrgInactive(props: ModalProps) {
    const { visible, toggleModal } = props;

    return (
        <Dialog
            open={visible}
        >
            <DialogSurface>
                <DialogBody style={{ color: "#586A84", padding: ".5em" }} >
                    <DialogTitle style={{ fontSize: "1.3rem", marginBottom: ".5em" }}>Organisation Inactive!</DialogTitle>
                    <DialogContent>
                        <h3 style={{ fontSize: "1.1rem" }}>Your organisation is currently inactive. Please contact our Sales Team at <span style={{ color: "#007AFF" }}>flyghtcloud@ideaforgetech.com</span> to refill credits and regain access to your projects.</h3>
                    </DialogContent>
                    <DialogActions>
                        <DialogTrigger disableButtonEnhancement>
                            <Button appearance="primary" style={{ background: "#007AFF", color: "white", borderRadius: "8px", padding: ".5em" }} onClick={() => {
                                toggleModal(false);
                            }
                            }>Okay</Button>
                        </DialogTrigger>
                    </DialogActions>
                </DialogBody>
            </DialogSurface >
        </Dialog >
    )
}

export default OrgInactive