import React from 'react'
import { useDrag } from 'react-dnd';
import { PluginResponse } from '../../services/openapi';

const ItemTypes = {
    NODE: 'node',
};

interface DraggableNodeProps {
    eachNode: PluginResponse
}

const DraggableNode = ({ eachNode }: DraggableNodeProps) => {
    const [{ isDragging }, drag] = useDrag({
        type: ItemTypes.NODE,
        item: eachNode,
        collect: (monitor: any) => ({
            isDragging: monitor.isDragging(),
        }),
    });

    return (
        <div
            ref={drag}
            style={{
                opacity: isDragging ? 0.5 : 1,
                cursor: 'move',
                padding: '12px',
                marginTop: "12px",
                marginBottom: "12px",
                marginRight: "10px",
                borderRadius: "8px",
                backgroundColor: '#F1F6FF',
                border: "1px solid #E3E9F2"
            }}
        >
            {eachNode.name}
        </div>
    );
};

export default DraggableNode;