import React from 'react';
import ReactFlow, { ReactFlowProvider, Controls, Background } from 'reactflow';
import 'reactflow/dist/style.css';
import CustomNode from './CustomNode';
import { parseMappingToFlow } from './ParseMappingToFlow';

interface FlowChartProps {
    mappingData: any;
}

const nodeTypes = {
    customNode: CustomNode,
};

const FlowChart: React.FC<FlowChartProps> = ({ mappingData }) => {
    const { nodes, edges }: any = parseMappingToFlow(mappingData);

    return (
        <div style={{ height: '100%' }}>
            <ReactFlowProvider>
                <ReactFlow
                    nodes={nodes}
                    edges={edges}
                    nodeTypes={nodeTypes}
                    fitView
                    style={{ background: '#f0f0f0', height: '100%' }}
                >
                    <Background />
                    <Controls />
                </ReactFlow>
            </ReactFlowProvider>
        </div>
    );
};

export default FlowChart;
