import { Toast, ToastTitle, Toaster, makeStyles, useId, useToastController } from '@fluentui/react-components';
import * as React from 'react';
import ToastErrorIcon from "../../assets/icons/toast_error.svg";
import ToastSuccessIcon from "../../assets/icons/toast_success.svg";

import { TaskFileResponse, TaskQueueService, WorkflowsService } from '../../services/openapi';
import { Loading } from '../Loading';
import { GeoJsonEditor } from './GeoJsonEditor';
import { PluginProps } from './PluginProps';

export const GeoJsonEditorPlugin = ({ flightId, workflowId, taskId, config, onFinish, onClose, workflow }: PluginProps) => {
    const [error, setError] = React.useState('');
    const [file, setFile] = React.useState<TaskFileResponse | null>(null);
    const classes = useStyles()
    const successToastID = useId("success");
    const failureToastID = useId("failed");
    const { dispatchToast: dispatchSuccessToast } = useToastController(successToastID);
    const { dispatchToast: dispatchFailedToast } = useToastController(failureToastID)

    const successToast = (message: string) => dispatchSuccessToast(
        <Toast
            style={{ background: "rgba(223, 246, 221, 1)", width: "100%" }}>
            <ToastTitle style={{ fontSize: "14px", fontWeight: 400 }} media={<img src={ToastSuccessIcon} alt="success icon" style={{ marginRight: "0.25rem" }} />}>{message}</ToastTitle>
        </Toast>,
        { intent: "success" }
    );

    const failureToast = (message: string) => dispatchFailedToast(
        <Toast
            style={{ background: "rgba(253, 231, 233, 1)", width: "100%" }}>
            <ToastTitle style={{ fontSize: "14px", fontWeight: 400 }} media={<img src={ToastErrorIcon} alt="error icon" style={{ marginRight: "0.25rem" }} />}>{message}</ToastTitle>
        </Toast>,
        { intent: "error" }
    );


    React.useEffect(() => {
        TaskQueueService.getFiles(taskId)
            .then((files) => {
                if (files.outputs.length === 1) {
                    setFile(files.outputs[0]);
                }
            })
            .catch((err) => {
                console.error(err);
                setError(`Could not get task's files: ${err}`);
            });
    }, [taskId]);

    const saveOutputFile = (contents: string): Promise<any> => {
        if (!file) return new Promise((resolve) => resolve(null));
        return TaskQueueService.getFilePresignedUrl(taskId, file?.id)
            .then((url) => {
                // do a put with the contents
                fetch(url, {
                    method: 'PUT',
                    body: contents
                }).then((res) => {
                    if (res.status === 200) {
                        // sync the file
                        syncFile();
                        successToast("Map File successfully saved")
                    } else {
                        console.error('Error', res);
                    }
                })
            })
            .catch((err) => {
                failureToast("File not saved due to network error. Please try later.")
            });
    };

    const syncFile = () => {
        if (!file) return;
        TaskQueueService.syncFile(taskId, file?.id)
            .then((_url) => {
                // do a put with the contents
            })
            .catch((err) => setError(`Could not sync file: ${err}`));
    };

    const finishTask = () => {
        WorkflowsService.updateTaskStatus(workflowId, taskId, { status: 'completed' })
            .then(() => {
                if (onFinish) {
                    onFinish();
                }
            })
            .catch((err) => setError(`Could not finish task: ${err}`));
    };

    if (error) {
        return <div>{error}</div>
    }

    if (!file) {
        return <Loading />
    }

    return <><GeoJsonEditor
        flightId={flightId}
        config={config}
        onSave={(c) => saveOutputFile(c)}
        onFinish={() => finishTask()}
        onClose={onClose}
        onDownloadSuccess={() => { successToast("Map File successfully downloaded") }}
        workflow={workflow}
    />
        <Toaster toasterId={successToastID} position="bottom" limit={1} className={classes.toaster} />
        <Toaster toasterId={failureToastID} position="bottom" limit={1} className={classes.toaster} />
    </>

}

const useStyles = makeStyles({
    toaster: {
        width: "600px",
    }
})