import { createContext } from 'react';


interface NodeSchema {
    nodeId: string;
    nodeName: string;
    schema: any;
}

interface workflowContextType {
    configData: NodeSchema[],
    setConfigData: React.Dispatch<React.SetStateAction<NodeSchema[]>>,
    isConfigSaved: boolean,
    setIsConfigSaved: React.Dispatch<React.SetStateAction<boolean>>,
    isBtnDisabled: boolean,
    setIsBtnDisabled: React.Dispatch<React.SetStateAction<boolean>>,
}

export const WorkFlowContext = createContext<workflowContextType>({
    configData: [],
    setConfigData: () => { },
    isConfigSaved: false,
    setIsConfigSaved: () => { },
    isBtnDisabled: true,
    setIsBtnDisabled: () => { },
});
