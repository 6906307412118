import { Button, Input, Textarea } from '@fluentui/react-components';
import * as React from 'react';

import { Link, useSearchParams, useNavigate } from "react-router-dom";
import { WorkflowTemplateResponse, WorkflowTemplatesService } from '../../services/openapi';
import { Header } from "../Header";
import { Loading } from '../Loading';
import { Editmodel } from './EditModel';
import { TemplateEditor } from "./TemplateEditor";

export const NewTemplate = ({ edit }: { edit?: boolean }) => {
    const [search] = useSearchParams();
    const [srcTemplateId, setSsrcTemplateId] = React.useState('');
    const [srcTemplate, setSrcTemplate] = React.useState<WorkflowTemplateResponse | null>();
    const [saving, setSaving] = React.useState(false);
    const [model, setModel] = React.useState<Editmodel>();
    const [name, setName] = React.useState('');
    const [description, setDescription] = React.useState('');
    const navigate = useNavigate();

    React.useEffect(() => {
        if (!search) return;
        const tId = search.get('from');
        if (!tId) return;
        setSsrcTemplateId(tId);
        WorkflowTemplatesService.getTemplate(tId)
            .then((t) => {
                setSrcTemplate(t);
                if (edit) {
                    setName(t.name);
                    setDescription(t.description || '');
                } else {
                    setName(`Copy of ${t.name}`);
                    setDescription(t.description || '');
                }
            })
            .catch((err) => console.error(err));
    }, [search, edit]);

    const save = () => {
        if (
            saving
            || !model
            || !model.isComplete()
            || !name
            || !description
        ) return;
        setSaving(true);
        const m = model;
        const request = {
            name,
            description,
            plugins: m.plugins.map((p) => {
                return {
                    id: p.id,
                    plugin_id: p.plugin.id,
                    config: p.config,
                };
            }).reverse(),
            edges: m.edges.map((e) => {
                return {
                    from_node: e.from,
                    to_node: e.to,
                    from_file_id: e.fromPort,
                    to_file_id: e.toPort,
                };
            }),
        };
        let promise: Promise<WorkflowTemplateResponse>;
        if (edit) {
            promise = WorkflowTemplatesService.editWorkflowTemplate(srcTemplateId, request as any);
        } else {
            promise = WorkflowTemplatesService.createWorkflowTemplate(request);
        }
        promise.then((res) => {
            navigate(`/workflows?templateId=${res.id}`)
        })
            .catch((err) => console.error(err))
            .finally(() => setSaving(false));
    }

    if (srcTemplateId && !srcTemplate) {
        return <Loading />;
    }

    return <>
        <Header>
            <div
                style={{
                    height: '100%',
                    flexGrow: 1,
                    display: 'flex',
                    justifyContent: 'space-between'
                }}
            >
                <div>
                    <Link to="/workflows">Workflows</Link>
                    / New Workflow Template
                </div>
            </div>
        </Header>
        {(srcTemplateId && !srcTemplate) && (
            <Loading />
        )}
        <div style={{ margin: '1em', height: 'calc(-5em + 100vh)', overflowY: 'auto' }}>
            <div style={{
                backgroundColor: '#FDFDFD',
                padding: '1em',
                marginBottom: '1em',
                borderRadius: '0.5em',
                boxShadow: '5px 1px 20px 0px rgba(0, 0, 0, 0.05)'
            }}>
                <div>
                    <Input
                        style={{
                            width: '100%',
                            backgroundColor: '#F2F6FA',
                            padding: '0.75em 0.625em 0.75em 1em',
                            border: '0em',
                            borderRadius: '0.5em',
                            alignItems: 'center',
                            marginBottom: '1em',
                        }}
                        placeholder='Workflow Title'
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                    />
                </div>
                <div>
                    <Textarea
                        style={{
                            width: '100%',
                            backgroundColor: '#F2F6FA',
                            padding: '0.75em 0.625em 0.75em 1em',
                            border: '0em',
                            borderRadius: '0.5em',
                            alignItems: 'center',
                            marginBottom: '1em',
                        }}
                        placeholder='Description'
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                    />
                </div>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'end',
                    }}
                >
                    <Button
                        appearance='primary'
                        disabled={
                            !model?.isComplete()
                            || !name
                            || !description
                        }
                        onClick={() => save()}
                    >Save</Button>
                </div>
            </div>
            <TemplateEditor base={srcTemplate || undefined} onChange={(m) => setModel(m)} />
        </div>
    </>;
};

