import { Button, Dialog, DialogActions, DialogBody, DialogContent, DialogSurface, DialogTitle, DialogTrigger, Input, Label } from '@fluentui/react-components';
import React from 'react'

interface ModalProps {
    visible: boolean;
    toggleModal: (flag: boolean) => void;
    wftInfo: { name: string, description: string };
    setWftInfo: (info: { name: string, description: string }) => void;
    handleSubmit: (ev: React.FormEvent) => void;
}

function AddInfo(props: ModalProps) {
    const { visible, toggleModal, wftInfo, setWftInfo, handleSubmit } = props;

    return (
        <Dialog
            open={visible}
        >
            <DialogSurface>
                <form onSubmit={handleSubmit}>
                    <DialogBody>
                        <DialogTitle>Add basic information</DialogTitle>
                        <DialogContent>
                            <div style={{ marginTop: "1em" }}>
                                <Label htmlFor={"name-input"} style={{ marginRight: "0.75rem" }}>
                                    Name *
                                </Label>
                                <div>
                                    <Input required id={"name-input"} style={{ width: "60%" }} value={wftInfo.name}
                                        onChange={(e) =>
                                            setWftInfo({ ...wftInfo, name: e.target.value })
                                        } />
                                </div>
                            </div>
                            <div style={{ marginTop: "1em" }}>
                                <Label htmlFor={"description-input"} style={{ marginRight: "0.75rem" }}>
                                    Description *
                                </Label>
                                <div>
                                    <Input required id={"description-input"} style={{ width: "60%" }} value={wftInfo.description}
                                        onChange={(e) =>
                                            setWftInfo({ ...wftInfo, description: e.target.value })
                                        } />
                                </div>
                            </div>
                        </DialogContent>
                        <DialogActions>
                            <DialogTrigger disableButtonEnhancement>
                                <Button appearance="secondary" onClick={() => {
                                    toggleModal(false);
                                    setWftInfo({ ...wftInfo, name: "", description: "" })
                                }

                                }>Close</Button>
                            </DialogTrigger>
                            <Button disabled={!wftInfo.name || !wftInfo.description} type="submit" appearance="primary">
                                Submit
                            </Button>
                        </DialogActions>
                    </DialogBody>
                </form>
            </DialogSurface>
        </Dialog >
    )
}

export default AddInfo