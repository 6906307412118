/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateProjectFileRequest } from '../models/CreateProjectFileRequest';
import type { PreSignedUrlType } from '../models/PreSignedUrlType';
import type { ProjectAccessResponse } from '../models/ProjectAccessResponse';
import type { ProjectFileResponse } from '../models/ProjectFileResponse';
import type { ProjectFilesResponse } from '../models/ProjectFilesResponse';
import type { ProjectRequest } from '../models/ProjectRequest';
import type { ProjectResponse } from '../models/ProjectResponse';
import type { ProjectsResponse } from '../models/ProjectsResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ProjectsService {

    /**
     * @param page
     * @param perPage
     * @param orgId
     * @returns ProjectsResponse List Projects
     * @throws ApiError
     */
    public static listProjects(
        page?: number | null,
        perPage?: number | null,
        orgId?: string | null,
    ): CancelablePromise<ProjectsResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/projects',
            query: {
                'page': page,
                'per_page': perPage,
                'org_id': orgId,
            },
        });
    }

    /**
     * @param requestBody
     * @returns ProjectResponse Create Project
     * @throws ApiError
     */
    public static createProject(
        requestBody: ProjectRequest,
    ): CancelablePromise<ProjectResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/projects',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param projectId
     * @param requestBody
     * @returns ProjectResponse Edit Project Details
     * @throws ApiError
     */
    public static editProjectDetails(
        projectId: string,
        requestBody: ProjectRequest,
    ): CancelablePromise<ProjectResponse> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/projects/{project_id}',
            path: {
                'project_id': projectId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param projectId
     * @returns any Archive project
     * @throws ApiError
     */
    public static archiveProject(
        projectId: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/projects/{project_id}/archive',
            path: {
                'project_id': projectId,
            },
        });
    }

    /**
     * @param projectId
     * @returns ProjectFilesResponse Get Project Files
     * @throws ApiError
     */
    public static getProjectFiles(
        projectId: string,
    ): CancelablePromise<ProjectFilesResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/projects/{project_id}/files',
            path: {
                'project_id': projectId,
            },
        });
    }

    /**
     * @param projectId
     * @param requestBody
     * @returns ProjectFileResponse Get Project Files
     * @throws ApiError
     */
    public static createProjectFile(
        projectId: string,
        requestBody: CreateProjectFileRequest,
    ): CancelablePromise<ProjectFileResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/projects/{project_id}/files',
            path: {
                'project_id': projectId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param projectId
     * @param fileId
     * @returns any Delete Project File
     * @throws ApiError
     */
    public static deleteProjectFile(
        projectId: string,
        fileId: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/projects/{project_id}/files/{file_id}',
            path: {
                'project_id': projectId,
                'file_id': fileId,
            },
        });
    }

    /**
     * @param urlType
     * @param projectId
     * @param fileId
     * @returns string Get Project Files
     * @throws ApiError
     */
    public static getProjectFilePresignedUrl(
        urlType: PreSignedUrlType,
        projectId: string,
        fileId: string,
    ): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/projects/{project_id}/files/{file_id}/presigned_url',
            path: {
                'project_id': projectId,
                'file_id': fileId,
            },
            query: {
                'url_type': urlType,
            },
        });
    }

    /**
     * @param projectId
     * @param fileId
     * @returns ProjectFileResponse Sync Project File
     * @throws ApiError
     */
    public static syncProjectFile(
        projectId: string,
        fileId: string,
    ): CancelablePromise<ProjectFileResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/projects/{project_id}/files/{file_id}/sync',
            path: {
                'project_id': projectId,
                'file_id': fileId,
            },
        });
    }

    /**
     * @param projectId
     * @returns ProjectAccessResponse Get Project Access Info
     * @throws ApiError
     */
    public static checkUserProjectAccess(
        projectId: string,
    ): CancelablePromise<ProjectAccessResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/projects/{project_id}/permissions',
            path: {
                'project_id': projectId,
            },
        });
    }

    /**
     * @param projectId
     * @returns ProjectResponse Get Project Summary
     * @throws ApiError
     */
    public static getProjectSummary(
        projectId: string,
    ): CancelablePromise<ProjectResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/projects/{project_id}/summary',
            path: {
                'project_id': projectId,
            },
        });
    }

}
