import { Map } from 'ol';
import { EventsKey } from 'ol/events';
import Draw from 'ol/interaction/Draw';
import { Pixel } from 'ol/pixel';
import { toLonLat } from 'ol/proj';
import VectorSource from 'ol/source/Vector';
import { Fill, Stroke, Style } from 'ol/style';
import {
  AreaRequest,
  ElevationRequest,
  MeasurementResponse,
  MeasurementsService,
} from '../../../services/openapi';

const handleDefaultTool = (
  olMap: Map,
  callbacks: {
    hideAnnotation: () => void;
    showAnnotation: (id: string) => void;
    listenersSetupCallback: (singleEventKey: EventsKey) => void;
  }
) => {
  callbacks.hideAnnotation();
  olMap.getViewport().style.cursor = 'default';
  const getFeatureAtPixel = (pixel: Pixel) => {
    const feature = olMap.getFeaturesAtPixel(pixel);
    return feature;
  };

  const handleMapClick = () => {
    const singleEventKey = olMap.on('click', (event) => {
      const pixel = olMap.getEventPixel(event.originalEvent);
      const features = getFeatureAtPixel(pixel);
      const featuresWithIDs =
        features.length > 0 &&
        features.filter((feature) => feature.get('id').length > 0);
      if (featuresWithIDs) {
        const id = features[0].get('id');
        callbacks.showAnnotation(id);
      } else {
        callbacks.hideAnnotation();
      }
    });
    callbacks.listenersSetupCallback(singleEventKey);
  };
  handleMapClick();
  return;
};

const addMapInteration = (
  olMap: Map,
  shape: 'LineString' | 'Polygon',
  callback: (draw: Draw) => void
) => {
  const drawStyle = new Style({
    fill: new Fill({
      color: 'rgba(4, 0, 255, 0.1)',
    }),
    stroke: new Stroke({
      color: '#006096',
      width: 2,
    }),
  });

  const vectorSource = new VectorSource({ wrapX: false });
  const drawLayer = new Draw({
    source: vectorSource,
    type: shape,
    style: drawStyle,
  });

  olMap.addInteraction(drawLayer);
  callback(drawLayer);
  return drawLayer;
};

const handleDistanceTool = (
  olMap: Map,
  layerID: string,
  callbacks: {
    addInteration: (draw: Draw) => void;
    listenersSetupCallback: (
      singleEventKey: EventsKey,
      doubleEventKey: EventsKey
    ) => void;
    onDrawEnd: (measurement: MeasurementResponse) => void;
    onError: () => void;
  }
) => {
  olMap.getViewport().style.cursor = 'crosshair';
  addMapInteration(olMap, 'LineString', callbacks.addInteration);

  const sendDistanceRequest = async (
    coordinates: any[],
    measurementID: string,
    end = false
  ) => {
    const lastTwoCoordinates = coordinates.slice(-2);
    const transformedCoordinates = lastTwoCoordinates.map((coord) =>
      toLonLat(coord)
    );
    const formattedCoordinates = transformedCoordinates.map((coord) => [
      coord[0],
      coord[1],
    ]);

    const getDistanceRequest = {
      measurement_id: measurementID,
      coordinates: formattedCoordinates,
    };

    await MeasurementsService.getDistance(layerID, getDistanceRequest)
      .then((measurement) => {
        if (end) {
          callbacks.onDrawEnd(measurement);
        }
      })
      .catch((err) => {
        console.error('ERROR GETTING DISTANCE', err);
        callbacks.onError();
      });
  };

  let mID: string = '';
  let coordinates: any[] = [];
  const singleEventKey = olMap.on('singleclick', async (event: any) => {
    const coordinate = event.coordinate;
    coordinates.push(coordinate);
    if (coordinates.length > 1) {
      if (coordinates.length === 2) {
        await MeasurementsService.createDistanceObject(layerID)
          .then((distance) => {
            mID = distance.id;
          })
          .catch((err) => console.error('Error creating distance', err));
      }
      sendDistanceRequest(coordinates, mID);
    }
  });

  const doubleEventKey = olMap.on('dblclick', async (event: any) => {
    if (coordinates.length === 1) {
      coordinates.push(event.coordinate);
      await MeasurementsService.createDistanceObject(layerID)
        .then((distance) => {
          mID = distance.id;
        })
        .catch((err) => console.error('Error creating distance', err));
      await sendDistanceRequest(coordinates, mID, true);
    } else if (coordinates.length >= 2) {
      coordinates.push(event.coordinate);
      await sendDistanceRequest(coordinates, mID, true);
    }
    mID = '';
    coordinates = [];
  });

  callbacks.listenersSetupCallback(singleEventKey, doubleEventKey);
};

const handleAreaTool = (
  olMap: Map,
  layerID: string,
  callbacks: {
    addInteration: (draw: Draw) => void;
    onDrawEnd: (measurement: MeasurementResponse) => void;
    onError: () => void;
  }
) => {
  olMap.getViewport().style.cursor = 'crosshair';
  const drawLayer = addMapInteration(olMap, 'Polygon', callbacks.addInteration);

  drawLayer.on('drawend', async (event) => {
    const feature = event.feature;
    const geometry = feature.getGeometry();
    const coordinates = (geometry as any).getCoordinates()[0];

    const transformedCoordinates = coordinates.map((coord: any) =>
      toLonLat(coord)
    );
    const formattedCoordinates =
      transformedCoordinates.length > 0
        ? transformedCoordinates.map((coord: any) => [coord[0], coord[1]])
        : [];

    const getAreaRequest: AreaRequest = { coordinates: formattedCoordinates };
    await MeasurementsService.getArea(layerID, getAreaRequest)
      .then((measurement) => {
        callbacks.onDrawEnd(measurement);
      })
      .catch((err) => {
        console.error('Something went wrong', err);
        callbacks.onError();
      });
  });
};

const handleElevationTool = (
  olMap: Map,
  layerID: string,
  callbacks: {
    listenersSetupCallback: (singleEventKey: EventsKey) => void;
    onDrawEnd: (measurement: MeasurementResponse) => void;
    onError: () => void;
  }
) => {
  olMap.getViewport().style.cursor = 'crosshair';

  const singleEventKey = olMap.on('singleclick', async (event) => {
    const [lon, lat] = toLonLat(event.coordinate);

    const pointData: ElevationRequest = {
      point: {
        lat: lat,
        lon: lon,
      },
    };

    await MeasurementsService.getElevation(layerID, pointData)
      .then((measurement) => {
        // setMapEventKeys((prev) => ({ ...prev, singleEventKey }));
        callbacks.onDrawEnd(measurement);
      })
      .catch((err) => {
        console.error('Error getting elevation');
        callbacks.onError();
      });
  });
  callbacks.listenersSetupCallback(singleEventKey);
};

export {
  handleAreaTool,
  handleDefaultTool,
  handleDistanceTool,
  handleElevationTool,
};
