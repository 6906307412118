import * as React from 'react';
import { PluginsResponse, PluginsService } from '../../services/openapi';
import DraggableNode from './DraggableNode';

const Sidebar = () => {
    const [myPluginNodes, setMyPluginNodes] = React.useState<PluginsResponse | null>(null)

    React.useEffect(() => {
        Promise.all([
            PluginsService.getOwnPlugins(),
            PluginsService.getPlugins(),
        ]).then(([own, all]) => {
            const merged = [];
            const ids: any = {};
            for (const p of own.plugins) {
                if (!ids[p.id]) {
                    merged.push(p);
                }
            }
            for (const p of all.plugins) {
                if (!ids[p.id]) {
                    merged.push(p);
                }
            }
            setMyPluginNodes({ plugins: merged });
        }).catch(err => console.error("Unable to fetch my plugins"));
    }, [])

    return (
        <div style={{ borderRight: "1px solid #E3E9F2" }}>
            {
                myPluginNodes && myPluginNodes.plugins.map((node, index) => (
                    <div key={index}>
                        <DraggableNode eachNode={node} key={index} />
                    </div>
                ))
            }
        </div >
    );
};


export default Sidebar;
