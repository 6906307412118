import { Button, Dialog, DialogActions, DialogBody, DialogContent, DialogSurface, DialogTitle, DialogTrigger, Field, Input, Label, makeStyles, Menu, MenuItem, MenuList, MenuPopover, MenuTrigger, Popover, PopoverSurface, PopoverTrigger, tokens } from "@fluentui/react-components";
import React, { useContext, useState } from "react";
import { ProjectRequest, ProjectResponse, ProjectsService, WorkflowTemplateResponse, WorkflowTemplatesService } from "../../services/openapi";
import { WorkflowCard } from "../WorkflowCard";
import { AuthContext } from "../../AuthContext";
import moreInfoIcon from "../../assets/icons/moreInfo.svg";

interface ModalProps {
    visible: boolean;
    toggleModal: (flag: boolean) => void;
    project: ProjectResponse;
    load: (page: number, orgId: string) => void;
}

const defaultWorkflowIDs = ["DEFAULT_MAPPING", "METASHAPE_MAPPING", "METASHAPE_MAPPING_W_GCPS", "ODM_MAP_STOCKPILE_REPORT", "METASHAPE_BFT_OUTPUT"]

const EditProjectDetailsModal = (props: ModalProps) => {
    const { orgId } = useContext(AuthContext);
    const classes = useStyles();
    const [isValidMail, setIsValidMail] = React.useState(false);
    const { visible, project, toggleModal, load } = props;
    const [formValues, setFormvalues] = useState<ProjectRequest>({
        name: project.name,
        workflowTemplate: project.workflowTemplate,
        organisationId: project.organisationId,
    });
    const [templates, setTemplates] = React.useState<WorkflowTemplateResponse[]>([]);

    React.useEffect(() => {
        WorkflowTemplatesService.getTemplates()
            .then((ts) => {
                const defaultTemplates = ts.templates.filter(t => defaultWorkflowIDs.includes(t.id));
                setTemplates(defaultTemplates);
            })
            .catch((err) => console.error(`Could not get workflow templates: ${err}`));
    }, [])


    const handleSubmit = (ev: React.FormEvent) => {
        ev.preventDefault();
        ProjectsService.editProjectDetails(project.id, formValues).then((res) => {
            toggleModal(false);
            if (orgId?.orgId) {
                load(0, orgId?.orgId)
            }
        }).catch((err) => {
            console.log("Err", err.body)
            setIsValidMail(true)
        });
    };

    const getTemplateName = () => {
        const t = (templates || []).find((t) => t.id === formValues?.workflowTemplate);
        return t?.name || '';
    };

    return (
        <Dialog
            open={visible}
        >
            <DialogSurface>
                <form onSubmit={handleSubmit}>
                    <DialogBody>
                        <DialogTitle>Edit Project Details</DialogTitle>
                        <DialogContent >
                            <div style={{ marginTop: '2em' }}>
                                <div className={classes.inputName}>
                                    Project Name *
                                </div>
                                <div>
                                    <Field validationMessageIcon={null} validationMessage={isValidMail ? "This project name already exists. Please choose a different name." : null}>
                                        <Input
                                            style={{
                                                width: '100%',
                                                border: isValidMail ? "1px solid red" : "none"

                                            }}
                                            className={classes.customInput}
                                            required id={"name-input"} value={formValues.name} onChange={(e) => {
                                                setIsValidMail(false)
                                                setFormvalues(prev => { return { ...prev, name: e.target.value } })
                                            }} />
                                    </Field>
                                </div>
                            </div>
                            <div style={{ marginTop: '2em' }}>
                                <div className={classes.inputName} style={{ display: "flex", alignItems: "center" }}>
                                    Default Workflow
                                    <Popover withArrow>
                                        <PopoverTrigger disableButtonEnhancement>
                                            <img style={{ marginLeft: ".5em", cursor: "pointer" }} src={moreInfoIcon} />

                                        </PopoverTrigger>

                                        <PopoverSurface className={classes.popoverSurface} tabIndex={-1} style={{ width: "20%" }}>
                                            <p>Default workflows are pre-built templates designed by industry experts, offering a quick start for common data analysis tasks.</p>
                                        </PopoverSurface>
                                    </Popover>
                                </div>
                                <Menu positioning={'below-start'}>
                                    <MenuTrigger     >
                                        <div>
                                            <Button size='large' style={{ width: "100%", height: "2rem", background: "#ECF3FF", border: "none", paddingTop: "1.3em", paddingBottom: "1.3em", borderRadius: "4px", justifyContent: "flex-start", fontWeight: "normal", fontSize: "14px" }}>
                                                {getTemplateName() || "-- Select One --"}
                                            </Button>
                                        </div>
                                    </MenuTrigger>
                                    <MenuPopover style={{ width: "550px", maxWidth: "550px" }} className={classes.dropdownPopover}>
                                        <MenuList style={{ overflowY: 'auto', maxHeight: '20em' }}>
                                            {templates.map((t) => {
                                                return <MenuItem
                                                    key={t.id}
                                                    style={{
                                                        backgroundColor: tokens.colorNeutralBackground1,
                                                        minWidth: "100%"
                                                    }}
                                                    onClick={() => {
                                                        setFormvalues({ ...formValues, workflowTemplate: t.id });
                                                    }}
                                                >
                                                    <WorkflowCard template={t} />
                                                </MenuItem>
                                            })}
                                        </MenuList>
                                    </MenuPopover>
                                </Menu>
                            </div>
                        </DialogContent>
                        <DialogActions>
                            <DialogTrigger disableButtonEnhancement>
                                <Button appearance="secondary" onClick={() => toggleModal(false)}>Close</Button>
                            </DialogTrigger>
                            <Button type="submit" appearance="primary">
                                Submit
                            </Button>
                        </DialogActions>
                    </DialogBody>
                </form>
            </DialogSurface>
        </Dialog >
    );
};

export default EditProjectDetailsModal;

const useStyles = makeStyles({
    inputName: {
        marginTop: "1em",
        marginBottom: "1em",
        fontWeight: "500",
        fontSize: "1.1rem"
    },
    customInput: {
        padding: ".6em",
        border: "none",
        background: "#ECF3FF",
        borderRadius: "4px",
    },
    popoverSurface: {
        transform: "translate3d(0,0, 0, 0) !important",
        boxShadow: " -1px 1px 10px 0px #00000040",
    },
    dropdownPopover: {
        width: "100% !important",
    },
})