/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DashboardResponse } from '../models/DashboardResponse';
import type { TimeRange } from '../models/TimeRange';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class DashboardService {

    /**
     * @param t
     * @param orgId
     * @returns DashboardResponse Get dashboard info
     * @throws ApiError
     */
    public static dashboard(
        t?: TimeRange | null,
        orgId?: string | null,
    ): CancelablePromise<DashboardResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/dashboard',
            query: {
                't': t,
                'org_id': orgId,
            },
        });
    }

}
