import * as React from 'react'
import { makeStyles } from '@fluentui/react-components'
import customizationIcon from "../../../../../assets/icons/landing/customization.svg";
import cbsIcon from "../../../../../assets/icons/landing/cbSolution.svg";
import miningSolutionIcon from "../../../../../assets/icons/landing/miningAutomation.svg"
import { SendRegular } from '@fluentui/react-icons';

function KeyFeatures() {
    const classes = useStyles();

    return (<>
        <div className={classes.kfContainer}>
            <h1 className={classes.heading}>Key Features and Benefits</h1>
            <div className={classes.boxContainer}>
                <div className={classes.kfAllBox}>
                    <div className={classes.kfBox}>
                        <img src={customizationIcon} alt='customizationIcon' />
                        <h2 className={classes.boxLabel}>Customization</h2>
                        <p className={classes.textHighLight}></p>
                        <div>
                            <p className={classes.boxinfo}><span className={classes.hightlight}><SendRegular /> Tailored features:</span> Unlike generic GIS software, pay for only what you use.</p>
                            <p className={classes.boxinfo}> <span className={classes.hightlight}><SendRegular /> API:</span> API for analytics from a wide range of software partners.</p>
                            <p className={classes.boxinfo}> <span className={classes.hightlight}><SendRegular /> Validation:</span> Tested end-to-end solution.</p>
                        </div>
                    </div>
                    <div className={classes.kfBox}>
                        <img src={cbsIcon} alt='cbsIcon' />
                        <h2 className={classes.boxLabel}>Cloud based solution</h2>
                        <p className={classes.textHighLight}></p>
                        <div>
                            <p className={classes.boxinfo}><SendRegular /> Low upfront investment</p>
                            <p className={classes.boxinfo}><SendRegular /> Enterprise data security</p>
                            <p className={classes.boxinfo}><SendRegular /> Scalability</p>
                            <p className={classes.boxinfo}><SendRegular /> Ease of collaboration and data dissemination</p>
                        </div>
                    </div>
                    <div className={classes.kfBox}>
                        <img src={miningSolutionIcon} alt='miningSolutionIcon' />
                        <h2 className={classes.boxLabel}>Automation</h2>
                        <p className={classes.textHighLight}></p>
                        <div>
                            <p className={classes.boxinfo}><SendRegular /> Error free image capture and data analytics</p>
                            <p className={classes.boxinfo}><SendRegular /> Quick turnaround time </p>
                            <p className={classes.boxinfo}><SendRegular /> Increase in the productivity of data analysts</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </>
    )
}

export default KeyFeatures

const useStyles = makeStyles({
    "kfContainer": {
        background: "#2B2F471A",
        position: "relative",
        height: "35vh",
    },
    heading: {
        textAlign: "center",
        fontSize: "2.5rem",
        paddingTop: "1em",
        paddingBottom: "1em",
        fontWeight: "bold"
    },
    boxContainer: {
        width: "100%",
        position: "absolute",
        top: "37%"
    },
    kfAllBox: {
        width: "80%",
        margin: "0 auto",
        display: "flex",
        justifyContent: "space-evenly"
    },
    kfBox: {
        border: '1px solid #707070',
        boxShadow: "rgb(192 181 181) 0px 1px 2px 0px, rgb(205 205 205) 0px 1px 3px 1px",
        width: "25%",
        height: 'calc(43vh - 1rem)',
        background: "white",
        display: "flex",
        padding: "2em",
        justifyContent: "start",
        flexDirection: "column",
        alignItems: "center",
        margin: "1em 0",
        ">img": {
            width: "20%"
        }
    },
    boxLabel: {
        fontSize: "1.2rem",
        lineHeight: "1rem",
        marginTop: ".5em",
        color: "#0A2C3D",
        fontWeight: "600"
    },
    boxinfo: {
        margin: '.3em 0',
        fontWeight: "400",
        color: "#0A2C3D"
    },
    hightlight: {
        fontWeight: "bold"
    },
    textHighLight: {
        width: "15%",
        marginTop: "1em",
        marginBottom: ".5em",
        padding: 0,
        height: "3px",
        background: "#1482DA"

    }

})