import React from 'react';
import { Handle, Position } from 'reactflow';

const CustomNode: React.FC<{ data: any }> = ({ data }) => {
    return (
        <div style={{ padding: '10px', border: '1px solid #777', borderRadius: '4px', background: '#fff' }}>
            {data.hasInput && (
                <Handle
                    type="target"
                    position={Position.Left}
                    style={{ background: '#555' }}
                />
            )}
            <div>{data.label}</div>
            {data.hasOutput && (
                <Handle
                    type="source"
                    position={Position.Right}
                    style={{ background: '#555' }}
                />
            )}
        </div>
    );
};

export default CustomNode;
