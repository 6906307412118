import {
    Button, Dialog,
    DialogActions,
    DialogBody, DialogContent, DialogSurface, DialogTitle, DialogTrigger,
    Tag,
    Text, Toast, ToastTitle, Toaster, makeStyles,
    shorthands, tokens,
    useId,
    useToastController
} from '@fluentui/react-components';
import { ArrowLeftRegular, Dismiss20Regular, Map24Regular } from '@fluentui/react-icons';
import moment from 'moment';
import * as React from 'react';
import Lottie from 'react-lottie';
import { Link, useLocation, useParams } from 'react-router-dom';
import { AuthContext } from '../AuthContext';
import AlertIcon from "../assets/icons/alert.svg";
import projectHeaderIcon from "../assets/icons/project_header.svg";
import ToastWarningIcon from '../assets/icons/toast_warning.svg';
import workComletedIcon from "../assets/icons/workCompleted.svg";
import workflowLoading from "../assets/lottie/loading2.json";
import { FeatureDemosService, FileResponse, FileTypeResponse, MapLayerResponse, ProjectAccessResponse, ProjectsService, TaskQueueService, WorkflowDetailsResponse, WorkflowStepResponse, WorkflowTemplatesService, WorkflowsService } from '../services/openapi';
import { getTimestampTime } from '../utils/datetime';
import { DownloadReportButton } from './DownloadReportButton';
import { ListCard, } from './ListCard';
import { Loading } from './Loading';
import { MapModal } from './MapModal';
import { TourContext, sourceTypes } from './TourContext';
import WorkFlowOutput from './WorkFlowOutput';
import { stepsData, workflowData } from './WorkflowData';
import { GeoJsonEditorPlugin } from './builtin_plugins/GeoJsonEditorPlugin';
import { WorkflowFileUploaderPlugin } from './builtin_plugins/WorkflowFileUploaderPlugin';
import { GCPTaggingPage } from './gcp_tagging';
interface Props {
    workflowId: string;
    hideHeader?: boolean;
}
interface PluginLink {
    flightId: string;
    workflowId: string;
    pluginId: string;
    taskId: string;
    config?: any;
}

interface FileData {
    id: string;
    file_type: string;
    status: string;
    size_mb?: number | null;
    defaultName: string;
    description: string;
}

export const Workflow = ({ workflowId, hideHeader }: Props) => {
    const classes = useStyles()
    const [wf, setWf] = React.useState<WorkflowDetailsResponse | null>();
    const [error, setError] = React.useState('');
    const [editUiProps, setEditUiProps] = React.useState<PluginLink | null>();
    const [mapLayers, setMapLayers] = React.useState<MapLayerResponse[] | null>();
    const [mergedWorkFlowFiles, setMergedWorkFlowFiles] = React.useState<FileData[] | undefined>([]);
    const { me: user } = React.useContext(AuthContext);
    const [showGCPModal, setShowGCPModal] = React.useState(false);
    const [isButtonDisabled, setIsButtonDisabled] = React.useState(false);
    const [projectAccess, setProjectAccess] = React.useState<ProjectAccessResponse | null>(null)
    const toasterId = useId("warning");
    const { dispatchToast } = useToastController(toasterId);
    const { startTour, source, setTourSource, isPrevious, stepIndex } = React.useContext(TourContext);
    const location = useLocation();
    const scrollableRef = React.useRef(null);

    React.useEffect(() => {
        if (source && source === sourceTypes.startOnboarding && !isPrevious) {
            startTour({ run: true, stepIndex: 17 });
            setTourSource(sourceTypes.startOnboarding);
            return;
        }
        FeatureDemosService.getAllFeatureDemos().then((features) => {
            if (!(features?.featureDemos.find(item => item.name === "projects"))) {
                return;
            } else {
                FeatureDemosService.getAllFeatureDemoViews().then((features) => {
                    if (features?.viewedFeatureDemos.find(item => item.name === "projects")) {
                        return;
                    }
                    else {
                        startTour({ run: true, stepIndex: 17 })
                    }
                });
            }
        });

        if (!wf) return;
        ProjectsService.checkUserProjectAccess(wf.projectId).then(permissions => {
            setProjectAccess(permissions)
        }).catch(err => console.error("Unable to fetch project access"))
    }, [location.pathname, wf]);


    const canDownload = !!projectAccess && projectAccess.canDownloadData;

    const scrollToElement = (targetSelector: string) => {
        if (scrollableRef && scrollableRef.current) {
            const targetElement = document.querySelector(targetSelector);
            if (targetElement) {
                const containerTop = (scrollableRef.current as any).getBoundingClientRect().top;
                const elementTop = targetElement.getBoundingClientRect().top;
                (scrollableRef.current as any).scrollTop += (elementTop - containerTop);
            }
        }
    };


    React.useEffect(() => {
        switch (stepIndex) {
            case 17: scrollToElement("#workflow-details-title"); break;
            case 18: scrollToElement("#workflow-details-step"); break;
            case 19: scrollToElement("#workflow-details-laststep"); break;
            case 20: scrollToElement("#workflow-details-output"); break;
            case 21: scrollToElement("#workflow-details-detailedOutput"); break;
        }
    }, [stepIndex])


    const warningToast = (message: string) => dispatchToast(
        <Toast
            style={{ background: "#FFF4CE", width: "100%" }}>
            <ToastTitle style={{ fontSize: "14px", fontWeight: 400 }} media={<img src={ToastWarningIcon} alt="error icon" style={{ marginRight: "0.25rem" }} />}>{message}</ToastTitle>
        </Toast>,
        { intent: "warning" }
    );

    const getAllWorkFlowOutputs = React.useCallback((workflowId: string) => {
        Promise.all([
            WorkflowsService.getFiles(workflowId),
            WorkflowTemplatesService.getFileTypes()
        ]).then(([filesResponse, fileTypesResponse]) => {
            const files = filesResponse.files;
            const fileTypes = fileTypesResponse.fileTypes;
            const filteredFileTypes = fileTypes.filter(type => [
                "mosaic",
                "dem",
                "point_cloud",
                "text",
                "bft_compat_dem",
                "stockpile_report_pdf",
            ].includes(type.id));
            mergedData(files, filteredFileTypes);
        }).catch((err) => console.error(err));
    }, []);

    const load = React.useCallback((workflowId: string) => {
        getAllWorkFlowOutputs(workflowId);
        WorkflowsService.getWorkflowDetails(workflowId)
            .then((wf) => setWf(wf))
            .catch((err) => setError(`Could not get workflow details: ${err}`));
    }, [getAllWorkFlowOutputs]);

    React.useEffect(() => {
        load(workflowId);
    }, [workflowId, load]);

    const skipWorkflowTask = (taskID: string) => {
        WorkflowsService.updateTaskStatus(workflowId, taskID, { status: "completed" }).then(() => {
            setShowGCPModal(false)
            load(wf?.id || '');
        }).catch((err) => console.error(err))
    }
    const mergedData = (files: FileResponse[], filteredFileTypes: FileTypeResponse[]) => {
        const mergedFiles = files.map(file => {
            // we should not show files for download which 
            // have only been linked from elsewhere in the project
            // and not actually been generated
            if (file.plugin_id === 'PROJECT_FILE_PICKER') {
                return undefined;
            }
            const fileType = filteredFileTypes.find(type => type.id === file.file_type);
            if (fileType) {
                return {
                    ...file,
                    defaultName: fileType.defaultName,
                    description: fileType.description
                };
            } else {
                return undefined;
            }
        });
        setMergedWorkFlowFiles(mergedFiles.filter(file => file !== undefined) as FileData[]);
    }

    const resetTask = (taskId: string | null) => {
        if (taskId) {
            WorkflowsService.resetTaskStatus(wf?.id || '', taskId)
                .then(() => {
                    load(wf?.id || '');
                })
                .catch((err) => console.error(err));
        }
    }

    const setTaskPriority = (taskId: string | null, priority: number) => {
        if (taskId) {
            TaskQueueService.updateTaskProperties(taskId, { priority })
                .then(() => {
                    load(wf?.id || '');
                })
                .catch((err) => console.error(err));
        }
    }

    const stopTask = (taskId: string | null) => {
        if (taskId) {
            TaskQueueService.updateTaskProperties(taskId, { forceStop: true })
                .then(() => {
                    load(wf?.id || '');
                })
                .catch((err) => console.error(err));
        }
    }

    const openPluginUi = (s: WorkflowStepResponse) => {
        if (!wf || !wf.flightId) return;
        if (!s.taskId) return;
        setEditUiProps({
            workflowId: wf.id,
            flightId: wf.flightId,
            pluginId: s.pluginId || '',
            taskId: s.taskId,
            config: s.config,
        });
    }

    const renderStepControls = (s: WorkflowStepResponse) => {
        if (s.status === 'completed') {
            // ignore, nothing to do
            return <></>
        }
        if (user?.admin) {
            if (s.status === 'failed') {
                return <div>
                    <Button
                        appearance='primary'
                        onClick={() => resetTask(s.taskId || null)}
                    >Reset</Button></div>
            }
            if (!!s.queueItemId) {
                return <div>
                    Priority:
                    <Button
                        style={{ padding: "10px 24px", color: "#5E5CE6", borderRadius: "4px" }}
                        onClick={() => setTaskPriority(s.queueItemId || '', (s.priority || 0) - 1)}
                    >-</Button>
                    {s.priority}
                    <Button
                        style={{ padding: "10px 24px", color: "#5E5CE6", borderRadius: "4px" }}
                        onClick={() => setTaskPriority(s.queueItemId || '', (s.priority || 0) + 1)}
                    >+</Button>
                    {(s.status === 'started' || s.status === 'pending') && (<Button
                        style={{ padding: "10px 24px", color: "#5E5CE6", borderRadius: "4px" }}
                        onClick={() => stopTask(s.queueItemId || '')}
                    >Request Force Stop</Button>)}
                </div>
            }
        }
        return <></>
    }

    React.useEffect(() => {
        if (!wf) {
            return
        }
        const hasPendingGCP = wf.steps.some(step => step.id === 'gcp_tagging' && step.status === 'pending');
        if (hasPendingGCP) {
            setShowGCPModal(true)
        }
        const hasPendingGeoJSON = wf.steps.some(step => step.id === 'edit_geojson' && step.status === 'pending');
        if (hasPendingGeoJSON) {
            warningToast("Workflow is on hold. Please upload your GeoJSON file to continue.")
        }
    }, [wf])

    if (error) {
        return <div>{error}</div>
    }

    if (!wf) {
        return <Loading />
    }

    const titleCase = (snakeCase: string) => snakeCase.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');

    const workflowActionButton = (step: WorkflowStepResponse) => {
        const pluginID = step.pluginId
        if (pluginID === 'GCP_TAGGING') {
            return <Button className={classes.workflowActionButton}><Link to={'gcptagging'}>Open UI</Link></Button>
        }
        if (pluginID === 'GEOJSON_EDITOR') {
            return <Button disabled={isButtonDisabled} onClick={() => openPluginUi(step)} className={classes.workflowActionButton} style={{ backgroundColor: "white" }}>Open</Button>
        }
        return <Button disabled={isButtonDisabled} onClick={() => openPluginUi(step)} className={classes.workflowActionButton}>Upload File</Button>
    }

    return <>
        {!hideHeader ?
            <div className={classes.projectHeaderText}>
                <div style={{ display: "flex", alignItems: "center" }}>
                    <Link style={{ textDecoration: "none" }} className={classes.flexContent} to="/projects"><img alt='Project' src={projectHeaderIcon} /> Projects</Link></div> <p style={{ margin: "0 .2em " }}>{'>'}</p> <Link style={{ textDecoration: "none" }} className={classes.flexContent} to={`/projects/${wf.projectId}`}>{wf.projectName}</Link><p style={{ margin: "0 .2em " }}>{'>'}</p>
                {wf.flightId ?
                    <><Link to={`/projects/${wf.projectId}/flights/${wf.flightId}`} style={{ textDecoration: "none" }} className={classes.flexContent} >{wf.flightName}</Link></>
                    : <></>
                }
                <p style={{ margin: "0 .2em " }}>{'>'}</p> {workflowData[wf.name] ? workflowData[wf.name] : wf.name}
            </div>
            : <></>}
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                backgroundColor: tokens.colorNeutralBackground1,
                width: '98%',
                margin: '1em',
                borderRadius: '1em',
                padding: '1em',
                paddingLeft: "32px",
                paddingBottom: "70px",
                boxShadow: '5px 1px 20px 0px rgba(0, 0, 0, 0.05)',
                height: "calc(92vh - 4.5rem)",
                overflow: "auto",
                scrollbarWidth: "thin",
            }}
            ref={scrollableRef}
        >
            <ListCard
            >
                <Link style={{
                    textDecoration: "none", fontSize: "1.5em",
                    fontWeight: "600",
                }} className={classes.flexContent} to={`/projects/${wf.projectId}/flights/${wf.flightId}`} id="workflow-details-title"><ArrowLeftRegular style={{ fontSize: "25px", marginRight: ".3em" }} /> {workflowData[wf.name] ? workflowData[wf.name] : wf.name} </Link>
                <Button onClick={() => load(workflowId)}>Refresh</Button>
            </ListCard>

            {wf.steps.map((s, idx) => {
                let id = idx === 0 ? "workflow-details-step" : idx === wf.steps.length - 1 ? "workflow-details-laststep" : undefined
                let color = { bg: '#ffffff', border: '#ffffff' };
                if (s.status === 'pending') {
                    color = { bg: '#F7FFFD', border: '#E3E9F2' };
                    if (s.id === 'gcp_tagging') {
                        color = { bg: "#FFD5D2", border: "#E3E9F2" }
                    }
                } else if (s.status === 'completed') {
                    color = { bg: '#F5FCFF', border: '#E3E9F2' };
                } else if (s.status === 'failed') {
                    color = { bg: '#FFD5D2', border: '#E3E9F2' };
                } else {
                    color = { bg: '#F2F2F2', border: '#E3E9F2' };
                }


                return <div
                    key={s.id}
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: "center"
                    }}
                >
                    {!s.status && <div style={{ marginLeft: "24px" }}></div>}
                    {
                        s.status === 'pending' ? s.id === "gcp_tagging" ?
                            <img className={classes.workFolwIcon} alt="alertIcon" src={AlertIcon} style={{ height: "24px", width: "24px", flexShrink: 0 }} />
                            : <Lottie options={{
                                animationData: workflowLoading,
                                loop: true,

                            }}
                                height="24px"
                                width="24px"
                            />
                            : null
                    }
                    {
                        s.status === 'completed' ? <img className={classes.workFolwIcon} alt='workComletedIcon' src={workComletedIcon} style={{ height: "24px", width: "24px", flexShrink: 0 }} /> : null
                    }


                    <div
                        style={{
                            flexGrow: 1,
                            padding: '8px',
                        }}
                    >
                        <div
                            style={{
                                width: '100%',
                                backgroundColor: color.bg,
                                border: `1px solid ${color.border}`,
                                borderRadius: '1em',
                                padding: '1em',
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                position: "relative"

                            }}
                        >
                            <div style={{ width: "100%" }}
                            >
                                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                    <div
                                        id={id}
                                    ><Text className={classes.workflowStepHeading}>{stepsData[s.id as string] ? stepsData[s.id as string].name : titleCase(s.id)}</Text>
                                        {s.status && s.status !== "completed" && <Tag role="listitem" className={classes.tagBar}>{s.status.charAt(0).toUpperCase() + s.status.slice(1)}</Tag>}
                                    </div>
                                </div>
                                <Text className={classes.workflowStepDescription}>{stepsData[s.id as string] ? s.status ? stepsData[s.id as string].states[s.status] : stepsData[s.id as string].states.default : `${s.pluginId} ${s.description}`}</Text>

                                <div style={{ position: "absolute", right: "2%", bottom: "4%" }}>
                                    {renderStepControls(s)}
                                </div>
                                <div style={{ marginTop: "24px" }}></div>
                                {
                                    s.status === 'pending' || s.status === 'processing' ?
                                        <p className={classes.timeText}>Start Time: {moment(getTimestampTime(s.startedAt as string)).local().format('DD/MM/YYYY, HH:mm')}</p>
                                        : null
                                }
                                {
                                    s.status === "completed" ? (
                                        <div style={{ display: "flex" }}>

                                            <p className={classes.timeText}>Start Time: {moment(getTimestampTime(s.startedAt as string)).local().format('DD/MM/YYYY, HH:mm')}</p>
                                            {
                                                s.endedAt ? <p style={{ marginLeft: "2em" }} className={classes.timeText}>End Time: {s.endedAt ? moment(getTimestampTime(s.endedAt as string)).format('DD/MM/YYYY, HH:mm') : 'xx:xx'}</p> : null

                                            }
                                        </div>
                                    ) : null
                                }
                            </div>
                            <div style={{ display: "flex", gap: "16px" }} >
                                {(s.editorUrl && s.taskId && s.status === 'pending') ? (
                                    workflowActionButton(s)
                                ) : <></>}
                                {(s.mapLayers.length > 0) && (
                                    <Button id="workflow-details-output" className={classes.workflowActionButton} appearance='subtle' onClick={() => setMapLayers(s.mapLayers)}>Show Maps <Map24Regular /></Button>
                                )}
                                {(s.status === "completed" && s.reports.length === 1) && (
                                    <DownloadReportButton title='Download Report' workflowId={workflowId} fileId={s.reports[0].id} disabled={!canDownload} />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            })}
            {mergedWorkFlowFiles && mergedWorkFlowFiles.some(file => file.status === "uploaded") ? (
                <div className={classes.workFlowOutput}>
                    <h2 className={classes.workflowOutputHeader} id="workflow-details-detailedOutput">Workflow Outputs</h2>
                    <div style={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
                        {
                            mergedWorkFlowFiles.length !== 0 && <div style={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
                                {mergedWorkFlowFiles && mergedWorkFlowFiles.map((file, index) => {
                                    const propsForWorkFlowOutput = {
                                        file_type: file.file_type,
                                        id: file.id,
                                        size_mb: file.size_mb,
                                        status: file.status,
                                        workflowId: workflowId,
                                        fileName: file.defaultName,
                                        fileDescription: file.description,
                                        canDownload,
                                    };

                                    return file.status === "uploaded" ? <WorkFlowOutput key={index} {...propsForWorkFlowOutput} /> : null
                                })
                                }
                            </div>
                        }
                    </div>
                </div>
            ) : null}

        </div >
        {!!editUiProps && <Dialog
            open={!!editUiProps}
            onOpenChange={(_, d) => {
                if (!d.open) {
                    setEditUiProps(null);
                }
            }}
        >
            <DialogTrigger></DialogTrigger>
            <DialogSurface style={{ minWidth: "60%", height: editUiProps.pluginId === 'WORKFLOW_FILE_UPLOADER' ? undefined : "75%", paddingTop: "12px" }}>
                <DialogBody style={{ height: "100%" }}>
                    <DialogContent>
                        {editUiProps.pluginId === 'WORKFLOW_FILE_UPLOADER' && (
                            <WorkflowFileUploaderPlugin
                                flightId={editUiProps.flightId}
                                workflowId={editUiProps.workflowId}
                                taskId={editUiProps.taskId}
                                onFinish={() => {
                                    setEditUiProps(null);
                                    load(editUiProps.workflowId);
                                }}
                                setBtnStatus={setIsButtonDisabled}
                            />
                        )}
                        {editUiProps.pluginId === 'GEOJSON_EDITOR' && (
                            <GeoJsonEditorPlugin
                                flightId={editUiProps.flightId}
                                workflowId={editUiProps.workflowId}
                                workflow={wf}
                                taskId={editUiProps.taskId}
                                config={editUiProps.config}
                                onFinish={() => { load(editUiProps.workflowId); setEditUiProps(null); }}
                                onClose={() => setEditUiProps(null)}
                            />
                        )}
                        {editUiProps.pluginId === 'GCP_TAGGING' && (
                            <GCPTaggingPage />
                        )}
                    </DialogContent>
                </DialogBody>
            </DialogSurface>
        </Dialog>
        }

        {!!mapLayers && <MapModal layers={mapLayers} visible={!!mapLayers} toggleModal={(flag) => { if (!flag) { setMapLayers(null) } }} />}

        {
            showGCPModal && <Dialog
                open={showGCPModal}
                onOpenChange={(_, d) => { setShowGCPModal(d.open) }}
                modalType='non-modal'
            >
                <DialogSurface >
                    <DialogBody style={{ gap: "16px" }}>
                        <DialogTitle
                            className={classes.gcpDialogHeader}
                            action={
                                <DialogTrigger action="close">
                                    <Button
                                        appearance="subtle"
                                        aria-label="close"
                                        icon={<Dismiss20Regular />}
                                    />
                                </DialogTrigger>
                            }>There are GCP’s present. Do you want to tag them?</DialogTitle>
                        <DialogContent className={classes.gcpDialogContent}>
                            For enhanced map accuracy and precise georeferencing, proceed to tag the Ground Control Points (GCPs).
                        </DialogContent>

                        <DialogActions className={classes.gcpDialogActionsContianer}>
                            <Button appearance="secondary" className={classes.gcpCloseActionButton} onClick={() => {
                                const step = wf.steps.find(item => item.id === "gcp_tagging" && item.status === "pending")
                                if (step) {
                                    skipWorkflowTask(step.taskId ?? "")
                                }
                            }}>Skip</Button>

                            <Link to={"gcptagging"} style={{ textDecoration: "none", display: "block" }}>
                                <Button appearance="primary" className={classes.gcpSuccessActionButton}>
                                    Yes, Tag GCP
                                </Button>
                            </Link>
                        </DialogActions>
                    </DialogBody>
                </DialogSurface>
            </Dialog >
        }

        <Toaster toasterId={toasterId} position="bottom" limit={1} className={classes.toaster} />
    </>;
}

export const WorkflowWrapper = () => {
    const { workflowId } = useParams();

    return <Workflow workflowId={workflowId || ''} />
};

const useStyles = makeStyles({
    projectHeaderText: {
        height: "3rem",
        paddingLeft: "1.25rem",
        display: "flex",
        alignItems: "center",
        color: "#2B2B2B",
        ...shorthands.borderBottom('1px', 'solid', '#E3E9F2'),

    },

    flexContent: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color: "black", fontWeight: "normal"
    },
    progressBar: {
        height: '8px',
        width: '30%',
        '& .ftywsgz': {
            backgroundColor: '#ACD47C',
        },
    },
    tagBar: {
        backgroundColor: "#8898B0",
        color: "white",
        height: "20px",
        paddingLeft: "10px",
        paddingRight: "10px",
        marginLeft: "24px",
        textAlign: "center",
        fontSize: "12px",
        fontWeight: 600,
        display: "inline-flex",
        alignItems: "center",
        justifyContent: "center"
    },
    workFolwIcon: {
        width: "25px"
    },
    workFlowOutput: {
        width: "99%",
        marginTop: "36px",
        paddingTop: "32px",
        paddingLeft: "32px",
        ...shorthands.borderTop('1px', 'solid', '#E3E9F2'),
    },
    gcpDialogHeader: {
        color: "#586A84",
        fontSize: "20px",
        fontWeight: 600,
        lineHeight: "28px",
        paddingTop: "8px"
    },
    gcpDialogContent: {
        color: "#586A84",
        fontSize: "18px",
        fontWeight: 400,
        lineHeight: "24px"
    },
    gcpDialogActionsContianer: {
        display: "flex",
        ...shorthands.gap("16px"),
        marginTop: "1rem",
    },
    gcpCloseActionButton: {
        display: "flex",
        paddingTop: "10px",
        paddingBottom: "10px",
        paddingLeft: "24px",
        paddingRight: "24px",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        ...shorthands.borderRadius("8px"),
        ...shorthands.border("1px", "solid", "#79747E"),
        color: "#79747E",
        textAlign: "center",
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "20px",
        letterSpacing: "0.1px"

    },
    gcpSuccessActionButton: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        ...shorthands.borderRadius("8px"),
        backgroundColor: "#5E5CE6",
        color: "#FFF",
        textAlign: "center",
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "20px",
        letterSpacing: "0.1px",
        paddingLeft: "24px",
        paddingRight: "24px",
        height: "100%",
    },
    workflowActionButton: {
        display: "flex",
        height: "40px",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        ...shorthands.gap("8px"),
        ...shorthands.borderRadius("8px"),
        ...shorthands.border("1px", "solid", "#79747E"),
        color: "#5E5CE",
        textAlign: "center",
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "20px",
        letterSpacing: "0.1px",
        backgroundColor: "transparent",
        whiteSpace: "nowrap",
        '&:hover': {
            backgroundColor: "#F4F4FF",
            color: "#5E5CE6",
        },
        '&:active': {
            backgroundColor: "#F4F4FF",
            color: "#5E5CE6",
            ...shorthands.border("1px", "solid", "#F4F4FF"),
        }
    },
    workflowStepHeading: {
        color: "#586A84",
        fontSize: "20px",
        fontStyle: "normal",
        fontWeight: 600,
        lineHeight: "28px"
    },
    workflowStepDescription: {
        color: "#586A84",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "20px"
    },
    timeText: {
        color: "#586A84",
        fontSize: "12px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "16px"
    },
    workflowOutputHeader: {
        color: "#586A84",
        fontSize: "28px",
        fontWeight: 600,
        lineHeight: "36px",
        marginBottom: "30px",
        display: "inline-block"
    },
    toaster: {
        width: "600px",
    }
})
