import * as React from "react";
import { Tab, TabList, makeStyles, shorthands, tokens } from '@fluentui/react-components'
import workFlow_icon from "../../assets/icons/nav_icons/workflows.svg";
import { deafultWorkFlowData } from "./common/DefaultWorkFlowData";
import WorkFlowCard from "./common/WorkFlowCard";
import NoFlightsFoundImage from "../../assets/no_flight_found.png";
import { Link } from "react-router-dom";


interface DemoLink {
    name: string;
    path: string;
    type: string;
}

type AllWorkFlowDataType = {
    id: string,
    icon: string,
    title: string,
    info: string,
    tagNames: string[],
    demoLink: DemoLink[];
}[]

const allTabs = ["Default Workflow", "My Workflows"]
const WorkFlowLibrary = () => {
    const classes = useStyles();
    const [workFlowsDatas, setWorkFlowsDatas] = React.useState<AllWorkFlowDataType | null>(null);
    const [selectedTab, setSelectedTab] = React.useState<string | null>(allTabs[0]);

    React.useEffect(() => {
        if (selectedTab === "Default Workflow") {
            setWorkFlowsDatas(deafultWorkFlowData)
        } else {
            setWorkFlowsDatas([])
        }
    }, [selectedTab])

    const NoWorkFlow = () => {
        return (
            <div className={classes.container}>
                <div className={classes.subContainer}>
                    <img
                        style={{ height: "80%" }}
                        src={NoFlightsFoundImage}
                        alt="No Flights Found"
                    />
                    <div
                        className={classes.subContainer}
                        style={{
                            flexDirection: "column",
                            maxWidth: "40%",
                            padding: "2em",
                        }}
                    >
                        <p className={classes.subheading}>Nothing to show here</p>
                        <p className={classes.subheadingInfo}>The workflows you've selected as favourites from the
                            <Link style={{ color: "black", fontWeight: "normal", textDecoration: "underline red" }} to="/discover-apps"> 'Discover apps' </Link>
                            section will be shown here.</p>
                    </div>
                </div>
            </div>
        );
    };

    return <>
        <div className={classes.projectHeaderText}>
            <div style={{ display: "flex", alignItems: "center" }}>
                <img alt="projectIcon" style={{ paddingRight: ".5em", }} src={workFlow_icon} />
            </div>
            <div style={{ fontSize: '1rem' }}>Workflow Library</div>
        </div>
        <div className={classes.bgContainer}
            style={{
                background: tokens.colorNeutralBackground1,
                overflow: "auto",

            }}
        >
            <div className={classes.subHeader}>
                <div style={{ display: "flex", padding: ".5em", color: "#043957", alignItems: "center" }}>
                    Workflow Library
                </div>
                <div className={classes.tabRoot}>
                    <TabList defaultSelectedValue={selectedTab} onTabSelect={(e: any) => { setSelectedTab(e.target.innerText) }}>
                        {
                            allTabs.map((ele, index) => <Tab key={index} value={ele}>{ele}</Tab>
                            )
                        }
                    </TabList>
                </div>
                <div>
                    {workFlowsDatas && selectedTab === "Default Workflow" ? (
                        <div className={classes.wrapper}>
                            {workFlowsDatas.map((data, index) => (
                                <div key={index} >
                                    <WorkFlowCard key={index} selectedTabValue={selectedTab} workFlowCardData={data} />
                                </div>
                            ))}
                        </div>
                    ) :
                        <NoWorkFlow />
                    }
                </div>
            </div>
        </div >

    </>
}
const useStyles = makeStyles({
    projectHeaderText: {
        height: "3rem",
        paddingLeft: "1.25rem",
        display: "flex",
        alignItems: "center",
        color: "#2B2B2B",
        ...shorthands.borderBottom("1px", "solid", "#E3E9F2"),
        "> span": {
            fontWeight: "700",
        },
    },
    flexContent: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    bgContainer: {
        width: "98%",
        ...shorthands.borderRadius("1em"),
        ...shorthands.padding("1em"),
        ...shorthands.margin("1em"),
        height: "calc(92vh - 4.2rem)",
        scrollbarWidth: "thin",
        boxShadow: "5px 1px 20px 0px rgba(0, 0, 0, 0.05)",
    },
    subHeader: {
        fontWeight: "600",
        fontSize: "1.5em",
    },
    tabRoot: {
        alignItems: "flex-start",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        rowGap: "20px",
    },
    wrapper: {
        display: "grid",
        gridTemplateColumns: "repeat(auto-fill, minmax(25%, 1fr))",
        marginTop: "1em",
        gridGap: '1em',
    },
    container: {
        display: "flex",
        ...shorthands.margin("5rem"),
        ...shorthands.gap("3.75rem"),
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
        height: "calc(48vh - 4rem)",
    },
    subContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    subheading: {
        color: "#586A84",
        marginLeft: "3em",
        fontSize: "20px",
        fontWeight: 600,
    },
    subheadingInfo: {
        color: "#586A84",
        marginLeft: "3em",
        marginTop: "1em",
        fontSize: "12px",
        fontWeight: 600,
    },

})

export default WorkFlowLibrary