import React from 'react'
import { makeStyles, shorthands, tokens } from '@fluentui/react-components';
import projectHeaderIcon from "../../assets/icons/project_header.svg";
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import Sidebar from './Sidebar';
import Flow from "./ReactFlow";
import { WorkFlowContext } from './common/WorkflowContext';
import AddConfig from './common/AddConfig';

interface Schema {
    properties: {
        quality: {
            default: string;
            description: string;
            type: string;
        };
    };
    required: string[];
    type: string;
}

interface NodeSchema {
    nodeId: string;
    nodeName: string;
    schema: Schema;
}

function WorkflowDesign() {
    const classes = useStyles();
    const [configData, setConfigData] = React.useState<NodeSchema[]>([]);
    const [isConfigSaved, setIsConfigSaved] = React.useState<boolean | false>(false);
    const [isBtnDisabled, setIsBtnDisabled] = React.useState<boolean | true>(true);
    
    return (
        <>
            <div className={classes.projectHeaderText}>
                <div style={{ display: "flex", alignItems: "center" }}>
                    <img src={projectHeaderIcon} alt="Projects" />
                    Workflow Design
                </div>
            </div>
            <div className={classes.workflowDesignContainer}>
                <WorkFlowContext.Provider value={{ configData, setConfigData, isConfigSaved, setIsConfigSaved, isBtnDisabled, setIsBtnDisabled }}>
                    <DndProvider backend={HTML5Backend}>
                        <div style={{ display: 'flex', height: '100%' }}>
                            <div style={{ width: "20%", overflowY: "auto", scrollbarWidth: "thin" }}> <Sidebar /></div>
                            <div style={{ width: "80%", height: "100%" }}> <Flow /></div>
                            <div style={{ width: "20%", borderLeft: "1px solid #E3E9F2", overflowY: "auto" }}> <AddConfig /></div>
                        </div>
                    </DndProvider>
                </WorkFlowContext.Provider >
            </div>
        </>
    )
}

export default WorkflowDesign;

const useStyles = makeStyles({
    projectHeaderText: {
        height: "3rem",
        paddingLeft: "1.25rem",
        display: "flex",
        alignItems: "center",
        color: "#2B2B2B",
        ...shorthands.borderBottom("1px", "solid", "#E3E9F2"),
        "> span": {
            fontWeight: "700",
        },
    },
    workflowDesignContainer: {
        width: "100%",
        background: tokens.colorNeutralBackground1,
        borderRadius: "1em",
        padding: ".5em",
        height: "calc(96vh - 4.5rem)",
        scrollbarWidth: "thin",
        boxShadow: "5px 1px 20px 0px rgba(0, 0, 0, 0.05)",
        position: "relative",
    }
})