import * as React from 'react';
import { AuthContext } from "../AuthContext";
import { Link, useParams } from 'react-router-dom';
import { RoleResponse, UserInfoResponse, OrganisationsService, UsersService } from '../services/openapi';
import { Header } from './Header';
import { Button, Dialog, DialogBody, DialogContent, DialogSurface, DialogTitle, Input, tokens, Toolbar } from '@fluentui/react-components';
import { PersonRegular } from '@fluentui/react-icons';

export const OrganisationMembers = () => {
    const { me: user, setIsAddedNewOrganisation } = React.useContext(AuthContext);
    const { orgId } = useParams();
    const [roles, setRoles] = React.useState<RoleResponse[] | null>();
    const [error, setError] = React.useState('');
    const [addError, setAddError] = React.useState('');
    const [search, setSearch] = React.useState('');
    const [showAddMember, setShowAddMember] = React.useState(false)
    const [newMemberData, setNewMemberData] = React.useState({ email: '', role: '' })

    React.useEffect(() => {
        OrganisationsService.getAllMemberRoles(orgId!)
            .then((roles) => setRoles(roles.roles))
            .catch((err) => setError(`Could not fetch Members ${err}`))
    }, [orgId])

    const removeMembers = (userID: string) => {
        OrganisationsService.removeOrganisationRole(orgId!, userID)
            .then(() => {
                OrganisationsService.getAllMemberRoles(orgId!)
                    .then((roles) => setRoles(roles.roles))
                    .catch((err) => setError(`Could not fetch Members ${err}`))
            })
            .catch((err) => setError(`Could not remove Member ${err}`))
    }

    const addMember = (email: string, role: string) => {
        UsersService.getUserByEmail(email)
            .then((newMemberInfo: UserInfoResponse) => {
                OrganisationsService.setOrganisationRole(orgId!, newMemberInfo.id, { role })
                    .then((_roleRes: RoleResponse) => {
                        setNewMemberData({ ...newMemberData, email: '', role: '' })
                        setShowAddMember(false)
                        OrganisationsService.getAllMemberRoles(orgId!)
                            .then((roles) => {
                                setRoles(roles.roles)
                                setIsAddedNewOrganisation(true)
                            })
                            .catch((err) => setError(`Could not fetch Members ${err}`))
                    })
                    .catch((error) => setAddError(`Could not fetch user by email ${error}`))
            })
            .catch((error) => setAddError(`Could not add Member ${error}`))
    }

    if (!user?.admin) {
        return <div>You are not authorized to use this page.</div>
    }

    return <div style={{ height: '96%' }}>
        <Header>
            <div
                style={{
                    flexGrow: 1,
                    display: 'flex',
                    justifyContent: 'space-between'
                }}
            >
                <div></div>
                <Button appearance="primary" style={{ paddingLeft: '2em', paddingRight: '2em', marginRight: '1em' }}>
                    <Link to="/admin/manageOrganisations" style={{ textDecoration: 'none', color: 'unset' }}>ManageOrganisations</Link>
                </Button>
            </div>
        </Header>
        <div style={{
            display: 'flex',
            paddingRight: '3em',
            flexDirection: 'column',
            height: "95%",
            margin: '1em',
            backgroundColor: tokens.colorNeutralBackground1,
            borderRadius: '0.5em',
            boxShadow: '5px 1px 20px 0px rgba(0, 0, 0, 0.05)'
        }}>
            <div style={{
                fontWeight: 600, fontSize: '2.5em',
                margin: '1em'
            }}>
                Organisation Members
            </div>

            {error && <div>{error}</div>}

            <Toolbar>
                <input
                    type="input"
                    onChange={(e) => setSearch(e.target.value)}
                    placeholder="Search user by email/Id/role"
                    style={{
                        width: '80%',
                        borderRadius: '1.5em',
                        padding: '1em 2em',
                        fontSize: '1.1em',
                        fontWeight: 'bold',
                        border: '0.01em solid black',
                        marginLeft: '0.5em',
                    }}
                />
                <Button style={{
                    width: "17%",
                    borderRadius: '1.5em',
                    fontSize: '1.1em',
                    paddingTop: '1em',
                    paddingBottom: '1em',
                    fontWeight: 'bold',
                    border: '0.01em solid black',
                    marginLeft: "1em",
                }}
                    onClick={() => setShowAddMember(true)}>
                    Add Member
                </Button>
            </Toolbar>

            <br />
            <div className='scrollablelist' style={{
                overflowY: 'scroll',
                height: '100%',
            }}>
                {roles && roles.filter((r) =>
                    r.role.toLowerCase().includes(search) || r.userId.includes(search)
                ).map((r) => {
                    return <div
                        key={r.userId}
                        style={{
                            padding: '0.8em',
                            margin: '0.8em',
                            backgroundColor: tokens.colorNeutralBackground1Hover,
                            borderRadius: '1em',
                            fontSize: '1.2em',
                        }}
                    >
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'end' }}>

                            <div>
                                <b>Role: </b>{r.role}<br />
                                <b>Role_ID: </b>{r.id}<br />
                                <b>User_ID: </b>{r.userId}<br />
                                <b>User Name: </b>{r.userName}<br />
                                <b>Email: </b>{r.email}<br />
                                <b>CreatedAt: </b>{r.createdAt}<br />
                                <b>UpdatedAt: </b>{r.updatedAt}<br />
                                <b>Active: </b>{r.active.toString()}<br />
                            </div>
                            <div style={{ flexGrow: "1" }}></div>
                            {/* <Button style={{ height: '20%', width: '15%', margin:'1em'}}
                                appearance="primary"
                                // onClick={() => {
                                        // navigate(`/admin/manageOrganisations/${org.id}`);
                                    // }
                                // }
                            >
                            Edit Member
                            </Button> */}
                            <Button style={{ height: '20%', width: '15%', margin: '1em' }}
                                appearance="primary"
                                onClick={() => {
                                    removeMembers(r.userId);
                                }
                                }
                            >
                                Remove Member
                            </Button>
                        </div>
                    </div>
                })}
            </div>
        </div >

        {showAddMember ?
            <Dialog modalType='non-modal' open={showAddMember} onOpenChange={(_, d) => {
                setShowAddMember(d.open)
                setNewMemberData({ ...newMemberData, email: '', role: '' })
                setAddError('')
            }}>
                <DialogSurface style={{ width: '60%', height: '70%' }}>
                    <DialogBody style={{ width: '100%', height: '100%' }}>
                        <DialogTitle style={{ fontSize: 'unset' }}>
                            Add Member
                        </DialogTitle>
                        <DialogContent
                            style={{
                                margin: 'auto',
                                width: '60%',
                                height: '60%',
                                justifyContent: 'space-between'
                            }}>
                            <div style={{ paddingBottom: '2em' }}>
                                <Input
                                    style={{
                                        width: '100%',
                                    }}
                                    appearance="outline"
                                    size="large"
                                    type="email"
                                    placeholder="Email Address"
                                    contentBefore={<PersonRegular />}
                                    value={newMemberData.email}
                                    onChange={(e) => {
                                        setNewMemberData({ ...newMemberData, email: e.target.value })
                                    }}
                                />
                            </div>
                            <div style={{ paddingBottom: '2em' }}>
                                <Input
                                    style={{
                                        width: '100%'
                                    }}
                                    appearance="outline"
                                    size="large"
                                    placeholder="Role"
                                    value={newMemberData.role}
                                    onChange={(e) => {
                                        setNewMemberData({ ...newMemberData, role: e.target.value })
                                    }}
                                />
                            </div>

                            <Button
                                style={{
                                    width: '100%',
                                    backgroundColor: '#0A84FF',
                                }}
                                appearance="primary"
                                onClick={() => {
                                    addMember(newMemberData.email, newMemberData.role);
                                }}
                                disabled={!newMemberData.email || !newMemberData.role}
                            >
                                Add
                            </Button>

                            <div style={{ color: 'red', fontWeight: 600, fontSize: '1em', marginLeft: '2em', marginTop: '2em' }}>
                                {!addError ? "" : addError}
                            </div>
                        </DialogContent>
                    </DialogBody>
                </DialogSurface>
            </Dialog>
            : <></>
        }
    </div>
}