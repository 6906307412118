import React, { useState, useEffect } from 'react';
import FlowChart from './FlowChart';
import { WorkflowTemplatesResponse, WorkflowTemplatesService } from '../../services/openapi';
import projectHeaderIcon from "../../assets/icons/project_header.svg";
import { makeStyles, shorthands, tokens } from '@fluentui/react-components';


const WorkflowTemplates: React.FC = () => {
    const classes = useStyles()
    const [workflowTemplates, setWorkflowTemplates] = useState<WorkflowTemplatesResponse | null>(null);
    const [selectedWorkflow, setSelectedWorkflow] = useState<string | null>(null);

    useEffect(() => {
        WorkflowTemplatesService.getTemplates()
            .then(res => {
                console.log("Fetched templates:", res);
                setWorkflowTemplates(res);
            })
            .catch(err => console.error("Unable to fetch getTemplates", err));
    }, []);

    const handleSelectWorkflow = (workflowId: string) => {
        setSelectedWorkflow(null)
        setSelectedWorkflow(workflowId);
    };

    const selectedMapping = workflowTemplates?.templates?.find(workflow => workflow.id === selectedWorkflow);

    return (<>
        <div className={classes.projectHeaderText}>
            <div style={{ display: "flex", alignItems: "center" }}>
                <img src={projectHeaderIcon} alt="Projects" />
                Workflow Templates
            </div>
        </div>
        <div className={classes.workflowDesignContainer} style={{ display: 'flex' }}>
            <div style={{ width: '200px', overflow: "auto", scrollbarWidth: "thin", padding: '10px', borderRight: '1px solid gray' }}>
                <ul>
                    {workflowTemplates ? (
                        workflowTemplates.templates.map(workflow => (
                            <li
                                key={workflow.id}
                                onClick={() => handleSelectWorkflow(workflow.id)}
                                style={{
                                    cursor: 'pointer',
                                    padding: '12px',
                                    marginTop: "12px",
                                    marginBottom: "12px",
                                    marginRight: "10px",
                                    borderRadius: "8px",
                                    backgroundColor: '#F1F6FF',
                                    border: "1px solid #E3E9F2"
                                }}
                            >
                                {workflow.name}
                            </li>
                        ))
                    ) : (
                        <li>Loading workflows...</li>
                    )}
                </ul>
            </div>
            <div style={{ flex: 3, padding: '10px' }}>
                <h2>{selectedMapping?.name}</h2>
                {selectedMapping && <FlowChart mappingData={selectedMapping} />}
            </div>
        </div >
    </>
    );
};

export default WorkflowTemplates;

const useStyles = makeStyles({
    projectHeaderText: {
        height: "3rem",
        paddingLeft: "1.25rem",
        display: "flex",
        alignItems: "center",
        color: "#2B2B2B",
        ...shorthands.borderBottom("1px", "solid", "#E3E9F2"),
        "> span": {
            fontWeight: "700",
        },
    },
    workflowDesignContainer: {
        width: "98%",
        background: tokens.colorNeutralBackground1,
        borderRadius: "1em",
        padding: "1em",
        margin: "1em",
        height: "calc(92vh - 4.5rem)",
        scrollbarWidth: "thin",
        boxShadow: "5px 1px 20px 0px rgba(0, 0, 0, 0.05)",
        position: "relative",

    }
})