import { makeStyles, shorthands, Tooltip } from "@fluentui/react-components";
import { useCallback } from "react";
import DeleteIcon from "../../assets/icons/deleteBlack.svg";
import AreaSelectedIcon from "../../assets/icons/ol/measurement_tools/area_selected.svg";
import DistanceSelectedIcon from "../../assets/icons/ol/measurement_tools/distance_selected.svg";
import FocusIcon from "../../assets/icons/ol/measurement_tools/focus.svg";
import ElevationSelectedIcon from "../../assets/icons/ol/measurement_tools/point_selected.svg";
import VisibilityHideIcon from "../../assets/icons/ol/measurement_tools/visibility_hide.svg";
import VisibilityShowIcon from "../../assets/icons/ol/measurement_tools/visibility_show.svg";

import { MeasurementResponse, MeasurementsService } from "../../services/openapi";

interface AnnotaionPanelProps {
    measurement: MeasurementResponse | null
    deleteMeasurementCallback: (id: string) => void
    onChangeName: (name: string) => void
    onChangeDescription: (description: string) => void
    name: string
    description: string
    onDeleteError: () => void
    onNameChangeError: () => void
    onDescriptionChangeError: () => void
    hideMeasurementCallback: (id: string) => void
    focusMeasurementCallback: (id: string) => void
    showMeasurementCallback: (id: string) => void
    measurementHidden: boolean
}

function debounce<T extends (...args: any[]) => void>(func: T, delay: number): (...args: Parameters<T>) => void {
    let timeout: NodeJS.Timeout;

    return function (...args: Parameters<T>) {
        clearTimeout(timeout);
        timeout = setTimeout(() => func(...args), delay);
    };
}

const annotationIconsByType: Record<string, string> = {
    area: AreaSelectedIcon,
    distance: DistanceSelectedIcon,
    elevation: ElevationSelectedIcon,
}

export function AnnotationPanel(props: AnnotaionPanelProps) {
    const { measurement, deleteMeasurementCallback, onChangeName, onChangeDescription, name, description, onDeleteError, onNameChangeError, onDescriptionChangeError, hideMeasurementCallback, focusMeasurementCallback, measurementHidden, showMeasurementCallback } = props;

    const classes = useStyles();


    const deleteMeasurement = (id: string) => {
        MeasurementsService.deleteMeasurement(id).then(() => {
            deleteMeasurementCallback(id);
        }).catch(err => { console.error("ERROR DELETING MEASUREMENT", err); onDeleteError() });
    }

    const getTotalDistance = (metadata: any) => {
        const features = metadata.features;

        const totalDistance = features.reduce((sum: number, feature: any) => {
            const distance = feature.properties["flyghtcloud.ideaforgetech.com/measurement"].Distance.value;
            return sum + distance;
        }, 0);

        return totalDistance;
    };

    const debouncedOnChangeName = useCallback(
        debounce((name: string) => {
            if (!measurement) {
                return;
            }
            MeasurementsService.editMeasurement(measurement.id, { name, description: measurement.description ?? "" }).catch(() => { onNameChangeError() });
        }, 300),
        [measurement]
    );

    const debouncedOnChangeDescription = useCallback(
        debounce((description: string) => {
            if (!measurement) {
                return;
            }
            MeasurementsService.editMeasurement(measurement.id, { name: measurement.name, description }).catch(() => { onDescriptionChangeError() });
        }, 300),
        [measurement]
    );


    return <div className={classes.annotationPanelContainer}
        style={{ width: measurement === null ? 0 : "30%", visibility: measurement === null ? "hidden" : "visible", transition: "all 0.2s", }}

    >
        <div className={classes.annotationPanelHeaderContainer}>
            <div className={classes.annotationPanelHeader}>
                <img src={annotationIconsByType[measurement?.measurement_type ?? "area"]} />
                <p>{measurement?.name || ""}</p>
            </div>

            <div className={classes.anotationActionsContainer}>
                <Tooltip content="Focus" relationship="label" hideDelay={0} appearance="inverted" positioning={"below-start"} showDelay={0} >
                    <img src={FocusIcon} onClick={() => focusMeasurementCallback(measurement?.id || "")} className={classes.annotationActionIcon} />
                </Tooltip>

                <Tooltip content={measurementHidden ? "Show" : "Hide"} relationship="label" hideDelay={0} appearance="inverted" positioning={"below-start"} showDelay={0}>
                    <img src={measurementHidden ? VisibilityShowIcon : VisibilityHideIcon}
                        onClick={() => {
                            if (measurementHidden) {
                                showMeasurementCallback(measurement?.id || "")
                            } else {
                                hideMeasurementCallback(measurement?.id || "")
                            }
                        }}
                        style={{ cursor: "pointer" }} className={classes.annotationActionIcon} />
                </Tooltip>

                <Tooltip content="Delete" relationship="label" hideDelay={0} appearance="inverted" positioning={"below-start"} showDelay={0}>
                    <img src={DeleteIcon} onClick={() => deleteMeasurement(measurement?.id || "")} style={{ cursor: "pointer" }} className={classes.annotationActionIcon} />
                </Tooltip>
            </div>
        </div>

        <div className={classes.annotationFormContainer}>
            <label htmlFor="name">Name</label>
            <input id="name" value={name} placeholder="Enter Name" onChange={(e) => {
                onChangeName(e.target.value)
                debouncedOnChangeName(e.target.value)
            }} />
        </div>

        <div className={classes.annotationFormContainer}>
            <label htmlFor="description">Description</label>
            <textarea id="description" value={description} placeholder="Enter Description" rows={3} onChange={(e) => {
                onChangeDescription(e.target.value)
                debouncedOnChangeDescription(e.target.value)
            }} />
        </div>

        <div className={classes.annotationValueContainer}>
            {measurement?.measurement_type === "area" && <>
                <p className={classes.annotationValueHeader}>Area</p>
                <p className={classes.annotationValueText}>{`${measurement?.metadata.features[0].properties["flyghtcloud.ideaforgetech.com/measurement"].Area.value.toFixed(2) ?? 0}m²`}</p>
            </>
            }
            {measurement?.measurement_type === "distance" && <>
                <p className={classes.annotationValueHeader}>Distance</p>
                <p className={classes.annotationValueText}>{`${getTotalDistance(measurement.metadata).toFixed(2)}m`}</p>
            </>}

            {measurement?.measurement_type === "elevation" && <div className={classes.elevationValueContainer}>
                <p className={classes.annotationValueHeader}>Elevation</p>
                <p className={classes.annotationValueText}>{`${measurement.metadata.features[0].properties["flyghtcloud.ideaforgetech.com/measurement"].Elevation.value.toFixed(2)}m`}</p>

                <p className={classes.annotationValueHeader}>Northing</p>
                <p className={classes.annotationValueText}>{`${measurement.metadata.features[0].properties["flyghtcloud.ideaforgetech.com/measurement"].Elevation.northing.toFixed(2)}m`}</p>

                <p className={classes.annotationValueHeader}>Easting</p>
                <p className={classes.annotationValueText}>{`${measurement.metadata.features[0].properties["flyghtcloud.ideaforgetech.com/measurement"].Elevation.easting.toFixed(2)}m`}</p>
            </div>}
        </div>
    </div>
}

const useStyles = makeStyles({
    annotationPanelContainer: {
        height: "100%",
        // width: "30%",
        position: "absolute",
        zIndex: "1000",
        backgroundColor: "#FFFFFF",
        ...shorthands.padding("24px", "16px"),
        display: "flex",
        flexDirection: "column",
        ...shorthands.gap("16px"),
        overflow: "hidden"

    },
    anotationActionsContainer: {
        display: "flex",
        ...shorthands.gap("16px"),
    },
    annotationActionIcon: {
        cursor: "pointer",
        ":hover": {
            backgroundColor: "rgba(228, 236, 248, 1)",
            borderRadius: "4px",
        }
    },
    annotationPanelHeaderContainer: {
        width: "100%",
        display: 'flex',
        justifyContent: "space-between",
        alignItems: 'center',
        height: "28px",
    },
    annotationPanelHeader: {
        display: "flex",
        maxWidth: "90%",
        alignItems: 'center',
        ...shorthands.gap("8px"),
        "& > p": {
            color: "#3E3E3E",
            fontSize: "16px",
            fontWeight: 600,
            lineHeight: "28px",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflow: "hidden"
        }
    },
    annotationFormContainer: {
        display: "flex",
        flexDirection: "column",
        ...shorthands.gap("8px"),
        "& > label": {
            color: "#5E5E5E",
            fontSize: "16px",
            fontWeight: 400,
            lineHeight: "28px"
        },
        "& > input , & > textarea": {
            ...shorthands.padding("6px", "10px"),
            alignItems: "center",
            color: "#000",
            fontSize: "14px",
            fontWeight: 400,
            lineHeight: "28px",
            ...shorthands.borderRadius("8px"),
            backgroundColor: "#F0F6FF",
            ...shorthands.border("none"),
            outline: "none",
            resize: "none",
            overflowY: "hidden",
            overflowX: "hidden"
        },
    },
    annotationValueContainer: {
        display: "flex",
        ...shorthands.gap("24px"),
        fontSize: "16px",
        fontWeight: 400,
        lineHeight: "28px",

    },
    annotationValueHeader: {
        color: "#5E5E5E",
    },
    annotationValueText: {
        color: "black"
    },
    elevationValueContainer: {
        display: 'grid',
        gridTemplateColumns: "1fr 1fr",
        gridTemplateRows: "1fr 1fr 1fr",
        columnGap: "24px",
        rowGap: "16px"
    }
})