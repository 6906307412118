import * as React from 'react'
import { Button, makeStyles, mergeClasses, shorthands } from '@fluentui/react-components';
import FlyghtCloudSmallIcon from "../../../assets/icons/flyghtcloud_small.svg";
import HamburgerIcon from "../../../assets/icons/hamburger.svg";
import {
    Drawer, DrawerBody,
    DrawerHeader,
} from '@fluentui/react-drawer';
import { Dismiss24Regular } from '@fluentui/react-icons';
import { NavLink, useNavigate } from 'react-router-dom';
import { AuthContext } from '../../../AuthContext';
import { AuthService } from '../../../services/openapi';
import { API_BASE } from '../../../constants';

function MobileHeader() {
    const classes = useStyles();
    const navigate = useNavigate();
    const { me: user, setMe } = React.useContext(AuthContext);

    const [isOpen, setIsOpen] = React.useState(false);

    const handleSignIn = () => {
        window.location.href = `${API_BASE}/api/sso/login?client_id=FLYGHTCLOUD`
    }

    const logout = () => {
        setMe(null)
        AuthService.logout()
            .catch((err) => console.error(err))
        navigate("/")
    }
    const navItems = [
        {
            href: "/",
            name: "Home",
        }
    ]

    return (
        <>
            <div className={classes["navbar-parent-container"]}>
                <div className={classes["navbar-logo-container"]}>
                    <img src={FlyghtCloudSmallIcon} alt='FlyghtCloudIcon' />
                    <p className={classes["navbar-logo-text"]}>FLYGHT<span>CLOUD</span></p>
                </div>
                <img style={{ cursor: "pointer" }} src={HamburgerIcon} alt='HamburgerIcon' onClick={() => setIsOpen(!isOpen)} />
            </div>
            <div>
                <Drawer position='end'
                    size='medium'
                    modalType="non-modal"
                    open={isOpen}
                    onOpenChange={(_, { open }) => setIsOpen(open)}
                    style={{
                        background: '#000000E5',
                        width: "50%",
                        color: "white"
                    }}
                >
                    <DrawerHeader className={classes['drawer-header']}>
                        <Button className={classes['close-btn']}
                            appearance="subtle"
                            aria-label="Close"
                            icon={<Dismiss24Regular style={{ color: "white" }} />}
                            onClick={() => {
                                setIsOpen(false)
                            }}
                        />
                    </DrawerHeader>

                    <DrawerBody style={{ display: "flex", background: "none", paddingTop: "2em", justifyContent: "center" }}>
                        <div>
                            <div>
                                {navItems.map(item =>
                                    <NavLink
                                        key={item.href}
                                        to={item.href}
                                        className={({ isActive }) =>
                                            mergeClasses(
                                                classes['navLinkContainer'],
                                                isActive && (classes["activeNavLink"])
                                            )
                                        }
                                    >
                                        {item.name}
                                    </NavLink>
                                )}
                            </div>
                            <div>
                                {user ? (
                                    <Button shape="circular"
                                        className={classes['logout-btn']}
                                        appearance="primary"
                                        onClick={logout}
                                        style={{ marginTop: "1em" }}
                                    >
                                        Logout
                                    </Button>
                                ) : (
                                    <Button
                                        shape="circular"
                                        className={classes['logout-btn']}
                                        appearance="primary"
                                        onClick={handleSignIn}
                                        style={{ marginTop: "1em" }}
                                    >
                                        Sign In
                                    </Button>
                                )}
                            </div>
                        </div>
                    </DrawerBody>
                </Drawer>
            </div>
        </>
    )
}

export default MobileHeader;

const useStyles = makeStyles({
    "navbar-parent-container": {
        width: "100%",
        height: "40px",
        ...shorthands.padding("8px", "16px"),
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        backgroundColor: "rgba(0, 0, 0, 0.50)"
    },
    "navbar-logo-container": {
        display: "flex",
        ...shorthands.gap("4px"),
        alignItems: "center"
    },
    "navbar-logo-text": {
        color: "#FFF",
        fontSize: "16px",
        "> span": {
            fontWeight: 700
        }
    },
    "drawer-header": {
        padding: "0",
        display: "flex",
        justifyContent: "end",
        flexDirection: "row",

    },
    "close-btn": {
        ":hover": {
            backgroundColor: "#000000E5",
        }
    },
    "navLinkContainer": {
        display: "flex",
        flexWrap: "wrap",
        alignItems: "center",
        justifyContent: "center",
        ...shorthands.gap("0.5rem"),
        ...shorthands.textDecoration("none"),
        fontSize: "1.2rem",
        textAlign: "center",
        color: "white",
        paddingBottom: ".2em",
        margin: "1.5em 0",
        ':hover': {
            ...shorthands.borderBottom("1px", "solid", "#E5E8EB"),
        },

    },
    "activeNavLink": {
        ...shorthands.borderBottom("2px", "solid", "#E5E8EB"),
        fontWeight: "bold",
    },
    "isActives": {
        ...shorthands.borderBottom("2px", "solid", "#242B35"),
        fontWeight: "bold"
    },
    "logout-btn": {}

})