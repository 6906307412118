import * as React from "react";
import { useParams } from "react-router-dom";
import { FileTypeResponse, PluginFile, PluginResponse, PluginsService, WorkflowTemplatesService } from "../../services/openapi";
import { Loading } from "../Loading";

interface Errors {
    name?: string;
    inputs?: string;
    outputs?: string;
}

const hasError = (e: Errors): boolean => {
    return !!e.name || !!e.inputs || !!e.outputs;
}

const validate = (p: PluginResponse): Errors => {
    const e: Errors = {};
    if (!p.name.trim()) {
        e.name = 'Name must not be empty';
    }
    let ids: any = {};
    for (const i of p.inputs) {
        if (!i.id || ids[i.id]) {
            e.inputs = 'All inputs must have unique non-empty ids';
            break;
        }
        ids[i.id] = true;
    }
    ids = {};
    for (const o of p.outputs) {
        if (!o.id || ids[o.id]) {
            e.outputs = 'All outputs must have unique non-empty ids';
            break;
        }
        ids[o.id] = true;
    }
    return e;
}

export const PluginEditor = ({ edit, create }: { edit?: boolean, create?: boolean }) => {
    const { pluginId } = useParams();
    const [types, setTypes] = React.useState<FileTypeResponse[] | undefined>();
    const [plugin, setPlugin] = React.useState<PluginResponse | undefined>();
    const [errors, setErrors] = React.useState<Errors>({});
    const [saving, setSaving] = React.useState(false);

    React.useEffect(() => {
        WorkflowTemplatesService.getFileTypes()
            .then(types => setTypes(types.fileTypes))
            .catch(err => console.error(err));
    }, [])

    React.useEffect(() => {
        if (create || pluginId === 'new') {
            setPlugin({
                id: '',
                name: '',
                description: '',
                inputs: [],
                outputs: [],
                pricePerUnit: 0,
                published: false,
                configSchema: {}
            });
        } else {
            PluginsService.getOwnPlugins()
                .then(plugins => {
                    for (const p of plugins.plugins) {
                        if (p.id === pluginId) {
                            setPlugin(p);
                        }
                    }
                })
                .catch(err => console.error(err));
        }
    }, [create, pluginId])

    React.useEffect(() => {
        if (plugin) {
            setErrors(validate(plugin));
        }
    }, [plugin]);

    const save = () => {
        setSaving(true);
        let fut;
        if (!plugin?.id) {
            fut = PluginsService.addPlugin({ ...plugin } as any);
        } else {
            fut = PluginsService.editPlugin(plugin.id, { ...plugin });
        }
        fut.then(p => setPlugin(p))
            .catch(err => console.error(err));
    }

    if (!plugin || !types) {
        return <Loading />;
    }

    return <div>
        <table border={1} width={'100%'}>
            <tbody>
                <tr>
                    <td>ID</td>
                    <td>
                        {plugin.id}
                    </td>
                </tr>
                <tr>
                    <td>Name</td>
                    <td>
                        <input
                            placeholder="name"
                            value={plugin.name}
                            disabled={!edit}
                            onChange={e => setPlugin({ ...plugin, name: e.target.value })}
                        />
                        {errors.name && (<div>{errors.name}</div>)}
                    </td>
                </tr>
                <tr>
                    <td>Description</td>
                    <td>
                        <textarea
                            placeholder="description"
                            value={plugin.description}
                            disabled={!edit}
                            onChange={e => setPlugin({ ...plugin, description: e.target.value })}
                        />
                    </td>
                </tr>
                <tr>
                    <td>Published</td>
                    <td>
                        <input
                            type="checkbox"
                            checked={plugin.published}
                            disabled={!edit}
                            onChange={e => setPlugin({ ...plugin, published: e.target.checked })}
                        />
                    </td>
                </tr>
                <tr>
                    <td>Inputs</td>
                    <td>
                        {plugin.inputs.map((i, idx) => {
                            return <File
                                // this is deliberate, see https://stackoverflow.com/a/59287017
                                // key={i.id}
                                i={i}
                                edit={!!edit}
                                types={types}
                                onChange={f => {
                                    plugin.inputs[idx] = f;
                                    setPlugin({ ...plugin, inputs: [...plugin.inputs] });
                                }}
                                onDelete={() => {
                                    plugin.inputs.splice(idx, 1);
                                    setPlugin({ ...plugin, inputs: [...plugin.inputs] });
                                }}
                            />
                        })}
                        <div>
                            <button
                                onClick={() => {
                                    setPlugin({ ...plugin, inputs: [...plugin.inputs, { id: '', config: { file_type: { file_type_id: '' } } }] });
                                }}
                            >Add input</button>
                        </div>
                        {errors.inputs && (<div>{errors.inputs}</div>)}
                    </td>
                </tr>
                <tr>
                    <td>Outputs</td>
                    <td>
                        {plugin.outputs.map((o, idx) => {
                            return <File
                                // this is deliberate, see https://stackoverflow.com/a/59287017
                                // key={o.id}
                                i={o}
                                edit={!!edit}
                                types={types}
                                onChange={f => {
                                    plugin.outputs[idx] = f;
                                    setPlugin({ ...plugin, outputs: [...plugin.outputs] });
                                }}
                                onDelete={() => {
                                    plugin.outputs.splice(idx, 1);
                                    setPlugin({ ...plugin, outputs: [...plugin.outputs] });
                                }}
                            />
                        })}
                        <div>
                            <button
                                onClick={() => {
                                    setPlugin({ ...plugin, outputs: [...plugin.outputs, { id: '', config: { file_type: { file_type_id: '' } } }] });
                                }}
                            >Add output</button>
                        </div>
                        {errors.outputs && (<div>{errors.outputs}</div>)}
                    </td>
                </tr>
                <tr>
                    <td>Config Schema</td>
                    <td>
                        <textarea
                            placeholder="config schema"
                            value={JSON.stringify(plugin.configSchema)}
                            disabled={!edit}
                            onChange={e => {
                                try {
                                    const cfg = JSON.parse(e.target.value);
                                    setPlugin({ ...plugin, configSchema: cfg });
                                } catch (ex) {
                                    console.error(ex);
                                }
                            }}
                        />
                    </td>
                </tr>
            </tbody>
        </table>
        <div>
            <button
                onClick={() => save()}
                disabled={hasError(errors)}
            >{plugin.id ? 'Update' : 'Create'}</button>
        </div>
        {hasError(errors) && <div>There are errors</div>}
    </div >;
};

const File = (
    { i, edit, types, onChange, onDelete }: {
        i: PluginFile,
        edit: boolean,
        types: FileTypeResponse[],
        onChange: (f: PluginFile) => void,
        onDelete: () => void,
    }
) => {
    const config = i.config as any;
    const multiple = config.file_type?.multiple || config.file_type_ref?.multiple;
    const fileType = !!config.file_type ? 'fixed' : 'dyn';
    return <div
        style={{ border: '1px solid silver', padding: '5px', margin: '5px' }}
    >
        <div>
            <input
                placeholder="id"
                value={i.id}
                disabled={!edit}
                onChange={e => {
                    i.id = e.target.value;
                    onChange({ ...i });
                }}
            />
            <input
                type="checkbox"
                checked={multiple}
                disabled={!edit}
                onChange={e => {
                    const checked = e.target.checked;
                    if (!!config.file_type) {
                        config.file_type.multiple = checked;
                    } else if (!!config.file_type_ref) {
                        config.file_type_ref.multiple = checked;
                    }
                    onChange(i);
                }}
            />
            Multiple?
            <select
                disabled={!edit}
                value={fileType}
                onChange={e => {
                    console.log(e.target.value);
                    if (e.target.value === 'fixed') {
                        i.config = { file_type: { multiple, file_type_id: '' } };
                    }
                    else if (e.target.value === 'dyn') {
                        i.config = { file_type_ref: { multiple, ref_field: '' } };
                    }
                    onChange(i);
                }}
            >
                <option value="fixed">Fixed Type</option>
                <option value="dyn">Configurable Type</option>
            </select>
            <button
                onClick={() => {
                    onDelete()
                }}
            >Delete</button>
        </div>
        <div>
            {!!config.file_type && (
                <>
                    File type
                    <select
                        disabled={!edit}
                        value={config.file_type.file_type_id || ''}
                        onChange={e => {
                            config.file_type.file_type_id = e.target.value;
                            onChange(i);
                        }}
                    >
                        <option value={''}></option>
                        {types.map(t => {
                            return <option key={t.id} value={t.id}>{t.description}</option>
                        })}
                    </select>
                </>
            )}
            {!!config.file_type_ref && (
                <>Field name in config: <input
                    disabled={!edit}
                    value={config.file_type_ref.ref_field || ''}
                    onChange={e => {
                        config.file_type_ref.ref_field = e.target.value;
                        onChange(i);
                    }}
                />
                </>
            )}
        </div>
    </div>
};