import React from 'react'
import { makeStyles } from '@fluentui/react-components';
import haradousIcon from "../../../../../assets/icons/landing/hazaradous.svg"
import costIcon from "../../../../../assets/icons/landing/cost.svg"
import labourIcon from "../../../../../assets/icons/landing/labour.svg"
import respiratoryIcon from "../../../../../assets/icons/landing/respiratory.svg"
import timeConsumeIcon from "../../../../../assets/icons/landing/timeConsume.svg"
import explosiveIcon from "../../../../../assets/icons/landing/expensive.svg"

function Limitations() {
    const classes = useStyles();

    return (
        <div>
            <h1 className={classes.heading}>Limitations of current methods</h1>
            <div className={classes["lmContainer"]}>
                <div className={classes['lmBoxContainer']} style={{ marginRight: "3em" }} >
                    <div className={classes.lmBox}>
                        <div className={classes.imgOuter}>
                            <div className={classes.imgInner}>
                                <img src={haradousIcon} alt='haradousIcon' />
                            </div>

                        </div>
                        <h2>Hazardous Working Condition</h2>
                    </div>
                    <div className={classes.lmBox}>
                        <div className={classes.imgOuter}>
                            <div className={classes.imgInner}>
                                <img src={respiratoryIcon} alt='respiratoryIcon' />
                            </div>

                        </div>
                        <h2>Respiratory hazards</h2>
                    </div>
                    <div className={classes.lmBox}>
                        <div className={classes.imgOuter}>
                            <div className={classes.imgInner}>
                                <img src={explosiveIcon} alt='explosiveIcon' />
                            </div>

                        </div>
                        <h2>Explosive hazards</h2>
                    </div>
                </div>
                <div className={classes['lmBoxContainer']} style={{ marginLeft: "3em" }}>
                    <div className={classes.lmBox}>
                        <div className={classes.imgOuter}>
                            <div className={classes.imgInner}>
                                <img src={labourIcon} alt='labourIcon' />
                            </div>

                        </div>
                        <h2>Labour Intensive</h2>
                    </div>
                    <div className={classes.lmBox}>
                        <div className={classes.imgOuter}>
                            <div className={classes.imgInner}>
                                <img src={timeConsumeIcon} alt='timeConsumeIcon' />
                            </div>

                        </div>
                        <h2>Time consuming</h2>
                    </div>
                    <div className={classes.lmBox}>
                        <div className={classes.imgOuter}>
                            <div className={classes.imgInner}>
                                <img src={costIcon} alt='costIcon' />
                            </div>

                        </div>
                        <h2>Cost burden</h2>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Limitations;

const useStyles = makeStyles({
    heading: {
        textAlign: "center",
        fontSize: "2.5rem",
        padding: "1.5em 0",
        fontWeight: "bold"
    },
    "lmContainer": {
        width: "85%",
        margin: "0 auto",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    "lmBoxContainer": {
        height: 'calc(40vh - 1rem)',
        width: "25%"
    },
    lmBox: {
        display: "flex",
        alignItems: "center",
        marginBottom: "1em",
        ">h2": {
            fontSize: "1.2rem",
            marginLeft: '.5em',
            fontWeight: "500"
        }
    },
    imgOuter: {
        width: "6vw",
        height: "6vw",
        maxWidth: "100px",
        maxHeight: "100px",
        border: "2px solid #E3E3E3",
        borderRadius: "50%",
        textAlign: "center",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    imgInner: {
        width: "5vw",
        height: "5vw",
        maxWidth: "100px",
        maxHeight: "100px",
        background: "#2B2F471A",
        borderRadius: "50%",
        textAlign: "center",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        ">img": {
            width: "60%",
            objectFit: "cover",
        },
    }
})