
import * as React from "react";
import { Button, Field, Input, makeStyles } from "@fluentui/react-components";
import { Eye16Regular, EyeOff16Regular } from "@fluentui/react-icons";
import { Link } from "react-router-dom";
import "./styles.css";
import useIsMobile from "../hooks/useIsMobile";
interface SignForm {
    fullName: string,
    email: string,
    password: string,
    confirmPassword: string
}

interface SignProps {
    onSubmit: (f: SignForm) => void;
    onChangeAuthForm: (f: string) => void;
    formData: SignForm;
    buttonState: boolean;
    initialState: SignForm;
    setSignUpValues: React.Dispatch<React.SetStateAction<SignForm>>;
}

const useStyles = makeStyles({
    primaryHeading: {
        fontSize: "1.2rem",
        fontWeight: 600,
        paddingTop: "1em",
        paddingBottom: "1em",
        textAlign: "center"
    },
    spanColor: {
        color: "#1570EF",
        marginTop: "1em",
        cursor: "pointer"
    },
    inputContainer: {
        paddingBottom: "1.5em",
        '& .f13qh94s': {
            display: "block"
        }
    }
});

export const Signup = (props: SignProps) => {
    const classes = useStyles();
    const isMobile = useIsMobile()

    const [passwordVisible, setPasswordVisible] = React.useState(false);
    const [confirmPasswordVisible, setConfirmPasswordVisible] = React.useState(false);
    const [isPasswordMatch, setIsPasswordMatch] = React.useState(false);
    const [passwordError, setPasswordError] = React.useState<string | null>(null);
    const [confirmPasswordError, setConfirmPasswordError] = React.useState<string | null>(null);
    const [isValidMail, setIsValidMail] = React.useState(false);

    const handleBackLogin = () => {
        props.setSignUpValues(props.initialState);
        props.onChangeAuthForm("login");
    }

    const handleSubmit = () => {
        const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[\W_]).{10,}$/;
        const isPasswordValid = passwordRegex.test(props.formData.password);

        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        setIsValidMail(!emailPattern.test(props.formData.email));
        if (!isValidMail && isPasswordValid && props.formData.password === props.formData.confirmPassword) {
            props.onSubmit(props.formData);
        }
        else if (!isPasswordValid) {
            setPasswordError("Incorrect password. Password must contain 10 characters,1 Uppercase, 1 Lowercase, 1 number  and 1 special character.");
            setIsPasswordMatch(false);
        } else if (props.formData.password !== props.formData.confirmPassword) {
            setIsPasswordMatch(true);
            setPasswordError(null);
            setConfirmPasswordError("Passwords do not match. Please try again.");
        }
    };

    return <div
        className="signUpContainer"
    >
        <h3 className={classes.primaryHeading}>Sign Up</h3>
        <form
            onSubmit={(e) => { e.preventDefault() }}
            style={{ marginTop: "1em", }}
        >
            <div className={classes.inputContainer}>
                <Input
                    className="supInput"
                    style={{
                        border: isMobile ? "1px solid #E5E5E5" : "1px solid #E5E5E5"
                    }}
                    appearance="outline"
                    size="large"
                    placeholder="Full Name"
                    value={props.formData.fullName}
                    onChange={(e) => {
                        props.setSignUpValues({ ...props.formData, fullName: e.target.value })
                    }}
                />
            </div>
            <div className={classes.inputContainer}>
                <Field validationMessageIcon={null} validationMessage={isValidMail ? "Incorrect email address" : null}>

                    <Input
                        className="supInput"
                        style={{
                            border: isMobile ? isValidMail ? "1px solid red" : "1px solid #E5E5E5" : isValidMail ? "1px solid red" : "1px solid #E5E5E5"
                        }}
                        appearance="outline"
                        size="large"
                        placeholder="Work Email"
                        value={props.formData.email}
                        onChange={(e) => {
                            setIsValidMail(false)
                            props.setSignUpValues({ ...props.formData, email: e.target.value })
                        }}
                    />
                </Field>
            </div>
            <div className={classes.inputContainer}>
                <Field validationMessageIcon={null} validationMessage={passwordError !== null ? passwordError : null}>
                    <Input
                        className="supInput"
                        style={{
                            border: isMobile ? passwordError !== null ? "1px solid red" : "1px solid #E5E5E5" : passwordError !== null ? "1px solid red" : "1px solid #E5E5E5"
                        }}
                        appearance="outline"
                        size="large"
                        placeholder="Password"
                        type={passwordVisible ? "text" : "password"}
                        contentAfter={passwordVisible ?
                            <EyeOff16Regular onClick={() => setPasswordVisible(false)} />
                            : <Eye16Regular onClick={() => setPasswordVisible(true)} />}
                        value={props.formData.password}
                        onChange={(e) => {
                            setPasswordError(null)
                            setIsPasswordMatch(false)
                            props.setSignUpValues({ ...props.formData, password: e.target.value })
                        }}
                    />
                </Field>
            </div>
            <div className={classes.inputContainer} style={{ marginTop: "0" }}>
                <Field validationMessageIcon={null} validationMessage={isPasswordMatch ? confirmPasswordError : null}>
                    <Input
                        className="supInput"
                        style={{
                            borderRadius: "8px",
                            border: isMobile ? isPasswordMatch ? "1px solid red" : "1px solid #E5E5E5" : isPasswordMatch ? "1px solid red" : "1px solid #E5E5E5"
                        }}
                        appearance="outline"
                        size="large"
                        placeholder="Confirm Password"
                        type={confirmPasswordVisible ? "text" : "password"}
                        contentAfter={confirmPasswordVisible ?
                            <EyeOff16Regular onClick={() => setConfirmPasswordVisible(false)} />
                            : <Eye16Regular onClick={() => setConfirmPasswordVisible(true)} />}
                        value={props.formData.confirmPassword}
                        onChange={(e) => {
                            setIsPasswordMatch(false)
                            props.setSignUpValues({ ...props.formData, confirmPassword: e.target.value })
                        }}
                    />
                </Field>
            </div>
            <p className="privacyLink" style={{ fontWeight: "500", fontSize: "0.9rem" }}>By signing in, you agree to our <Link className={classes.spanColor} to={`/eula`}            >
                End User License Agreement
            </Link> and  <Link className={classes.spanColor} to={`/privacy-policy`}            >
                    Privacy Policy
                </Link>.</p>

            <div style={{ marginTop: "1rem" }}>
                <Button shape="circular"
                    style={{
                        width: '100%', background: "#0A84FF", color: "white", padding: "11px",
                    }}
                    onClick={handleSubmit}
                    disabled={!props.formData.fullName || !props.formData.email || !props.formData.password || !props.formData.confirmPassword || props.buttonState}
                    type="submit"
                >
                    Create Account
                </Button>
            </div>
            <p className={classes.spanColor} onClick={handleBackLogin}>{'<<'} Back to Login  </p>
        </form>
    </div >

};
