import { Feature, Map } from 'ol';
import { LineString, MultiPoint, Point, Polygon } from 'ol/geom';
import VectorLayer from 'ol/layer/Vector';
import { fromLonLat } from 'ol/proj';
import VectorSource from 'ol/source/Vector';
import { Fill, Icon, Stroke, Style, Text } from 'ol/style';
import {
  MapLayerResponse,
  MeasurementResponse,
  MeasurementsService,
} from '../../../services/openapi';

const drawArea = (measurement: MeasurementResponse, olMap: Map) => {
  const coordinates = measurement.metadata.features[0].geometry.coordinates;
  const area =
    measurement.metadata.features[0].properties[
      'flyghtcloud.ideaforgetech.com/measurement'
    ].Area.value;
  const transformedCoordinates = coordinates.map((ring: any) =>
    ring.map((coord: any) => fromLonLat(coord))
  );

  const polygon = new Polygon(transformedCoordinates);

  const polygonFeature = new Feature({
    geometry: polygon,
    id: measurement.id,
  });

  const vectorSource = new VectorSource({
    features: [polygonFeature],
  });

  const vectorLayer = new VectorLayer({
    source: vectorSource,
    style: (feature) => [
      new Style({
        fill: new Fill({
          color: 'rgba(4, 0, 255, 0.1)',
        }),
        stroke: new Stroke({
          color: '#FFF',
          width: 4,
        }),
      }),
      new Style({
        stroke: new Stroke({
          color: '#006096',
          width: 2,
        }),
        text: new Text({
          text: `${area.toFixed(2)}m²`,
          font: '600 14px "Segoe UI"',
          fill: new Fill({
            color: '#000',
          }),
          stroke: new Stroke({
            color: '#FFF',
            width: 0.5,
          }),
          textAlign: 'center',
          textBaseline: 'middle',
          offsetY: 0,
        }),
      }),
      new Style({
        image: new Icon({
          src: '/assets/measurement_vertex_circle.svg',
        }),
        geometry: function (feature) {
          const coordinates = (
            feature.getGeometry() as any
          ).getCoordinates()[0];
          return new MultiPoint(coordinates);
        },
      }),
    ],
    zIndex: 999,
    properties: {
      tag: measurement.id,
    },
  });

  olMap.addLayer(vectorLayer);
};

const drawDistance = (measurement: MeasurementResponse, olMap: Map) => {
  const features = measurement.metadata.features;

  features.forEach((feature: any) => {
    const coordinates = feature.geometry.coordinates;
    const distance =
      feature.properties['flyghtcloud.ideaforgetech.com/measurement'].Distance
        .value;
    const transformedCoordinates = coordinates.map((coord: any) =>
      fromLonLat(coord)
    );

    const lineString = new LineString(transformedCoordinates);
    const lineFeature = new Feature({
      geometry: lineString,
      id: measurement.id,
    });

    const vectorSource = new VectorSource({
      features: [lineFeature],
    });

    const vectorLayer = new VectorLayer({
      source: vectorSource,
      style: () => [
        new Style({
          stroke: new Stroke({
            color: '#FFF',
            width: 4,
          }),
        }),
        new Style({
          stroke: new Stroke({
            color: '#006096',
            width: 2,
          }),
          text: new Text({
            text: `${distance.toFixed(2)} m`,
            font: '600 14px "Segoe UI"',
            fill: new Fill({
              color: '#000',
            }),
            stroke: new Stroke({
              color: '#FFF',
              width: 0.5,
            }),
            textAlign: 'center',
            textBaseline: 'middle',
            offsetY: -10,
          }),
        }),
        new Style({
          image: new Icon({
            src: '/assets/measurement_vertex_circle.svg',
          }),
          geometry: function (feature) {
            const coordinates = (feature.getGeometry() as any).getCoordinates();
            return new MultiPoint(coordinates);
          },
        }),
      ],
      zIndex: 999,
      properties: {
        tag: measurement.id,
      },
    });

    olMap.addLayer(vectorLayer);
  });
};

const drawElevation = (measurement: MeasurementResponse, olMap: Map) => {
  const coordinates = measurement.metadata.features[0].geometry.coordinates;
  const transformedCoordinates = fromLonLat(coordinates);

  const svgMarker = new Feature({
    geometry: new Point(transformedCoordinates),
    id: measurement.id,
  });

  const svgIcon = new Style({
    image: new Icon({
      src: '/assets/elevation_point.svg',
    }),
  });

  svgMarker.setStyle(svgIcon);

  const vectorSource = new VectorSource({
    features: [svgMarker],
  });

  const vectorLayer = new VectorLayer({
    source: vectorSource,
    zIndex: 999,
    properties: {
      tag: measurement.id,
    },
  });

  olMap.addLayer(vectorLayer);
};

const drawMeasurementsByType = (
  measurement: MeasurementResponse,
  olMap: Map
) => {
  const type = measurement.measurement_type.toLowerCase();
  switch (type) {
    case 'area':
      drawArea(measurement, olMap);
      break;
    case 'distance':
      drawDistance(measurement, olMap);
      break;
    case 'elevation':
      drawElevation(measurement, olMap);
      break;
  }
};

const drawMeasurements = async (
  olMap: Map,
  layer: MapLayerResponse,
  callback: (measurements: MeasurementResponse[]) => void
) => {
  const layerID = layer.id;
  const flightID = layer.flightId ?? '';

  let drawMeasurements: MeasurementResponse[] | null = null;
  await MeasurementsService.listMeasurements(flightID).then((measurement) => {
    if (measurement) {
      const filteredMeasurements = measurement.measurements.filter(
        (item) => item.map_layer_id === layerID
      );
      callback(filteredMeasurements);
      drawMeasurements = filteredMeasurements;
    }
  });

  if (drawMeasurements !== null) {
    (drawMeasurements as MeasurementResponse[]).forEach((measurement) => {
      drawMeasurementsByType(measurement, olMap);
    });
  }
};

export { drawMeasurements, drawMeasurementsByType };
