import React, { useCallback, useEffect, useRef } from 'react';
import ReactFlow, {
    MiniMap,
    Controls,
    Background,
    useNodesState,
    useEdgesState,
    addEdge,
    Connection,
    Edge,
    Node,
    ReactFlowProvider,
    MarkerType,
} from 'reactflow';
import 'reactflow/dist/style.css';
import { useDrop } from 'react-dnd';
import CustomNode from './CustomNode';
import CustomEdge from './CustomEdge';
import { DocumentAddRegular, SaveRegular } from '@fluentui/react-icons';
import { Button, Toast, Toaster, ToastTitle, useId, useToastController } from '@fluentui/react-components';
import AddInfo from './common/AddInfo';
import { WorkFlowContext } from './common/WorkflowContext';
import { WorkflowTemplatesService } from '../../services/openapi';

const initialNodes: Node<any, string | undefined>[] = [];
const initialEdges: Edge<any>[] = [];

const ItemTypes = {
    NODE: 'node',
};


const getId = (nodeName: string, nodes: Node<any, string | undefined>[]): string => {
    const existingNodes = nodes.filter(node => node.data.name.startsWith(nodeName));
    const duplicateCount = existingNodes.length;
    return duplicateCount > 0 ? `${nodeName}_${duplicateCount + 1}` : nodeName;
};

const edgeTypes = {
    custom: CustomEdge,
}
const nodeTypes = {
    custom: CustomNode,
};


function Flow() {

    const { configData, setConfigData, isConfigSaved, setIsConfigSaved } = React.useContext(WorkFlowContext);
    const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
    const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);
    const [wftInfo, setWftInfo] = React.useState({ name: "", description: "" });
    const [addInfoModalVisible, setAddInfoModalVisible] = React.useState(false);
    const flowWrapper = useRef<HTMLDivElement>(null);
    const toasterId = useId("toaster");
    const { dispatchToast } = useToastController(toasterId);



    const notifySucess = (msg: string) => dispatchToast(
        <Toast
            style={{ background: "#DCF7EFEF", width: "500px" }}>
            <ToastTitle style={{ fontSize: "14px", fontWeight: 400 }}>{msg}</ToastTitle>
        </Toast>,
        { intent: "success" }
    );

    const notifyError = (err: string) => dispatchToast(
        <Toast
            style={{ background: "#FDE7E9", width: "500px" }}>
            <ToastTitle style={{ fontSize: "14px", fontWeight: 400 }}>{err}</ToastTitle>
        </Toast>,
    );


    const onConnect = useCallback(
        (params: Edge | Connection) => {
            const sourceNode = nodes.find(node => node.id === params.source);
            const targetNode = nodes.find(node => node.id === params.target);

            if (!sourceNode || !targetNode) {
                notifyError('Invalid source or target node.');
                return;
            }

            const sourcePort = params.sourceHandle?.split('-')[1];
            const targetPort = params.targetHandle?.split('-')[1];

            if (!sourcePort || !targetPort) {
                notifyError('Invalid source or target port.');
                return;
            }

            const sourceIsOutput = sourceNode?.data.outputs.some((output: any) => output.id === sourcePort);
            const targetIsInput = targetNode?.data.inputs.some((input: any) => input.id === targetPort);

            if (!sourceIsOutput || !targetIsInput) {
                notifyError('Connections should flow from output to input.');
                return;
            }

            const sourceFileType = sourceNode?.data.outputs.find((output: any) => output.id === sourcePort)?.config.file_type?.file_type_id;
            const targetFileType = targetNode?.data.inputs.find((input: any) => input.id === targetPort)?.config.file_type?.file_type_id;

            if (!sourceFileType) {
                notifyError(`Source node '${sourceNode.data.name}' is missing a file type.`);
                return;
            }

            if (!targetFileType) {
                notifyError(`Target node '${targetNode.data.name}' is missing a file type.`);
                return;
            }

            // Check for file type compatibility
            if (sourceFileType !== targetFileType) {
                notifyError(`Incompatible file types! Source type: ${sourceFileType}, Target type: ${targetFileType}`);
                return;
            }

            // Validate existing connections to the source and target ports
            const targetPortConnections = edges.filter(edge => edge.target === params.target && edge.targetHandle === params.targetHandle).length;
            const sourcePortConnections = edges.filter(edge => edge.source === params.source && edge.sourceHandle === params.sourceHandle).length;

            if (targetPortConnections >= 1) {
                notifyError(`The target port '${targetPort}' already has 1 input connection.`);
                return;
            }

            if (sourcePortConnections >= 1) {
                notifyError(`The source port '${sourcePort}' already has 1 output connection.`);
                return;
            }

            // Add the edge if all checks pass
            setEdges((eds) =>
                addEdge(
                    {
                        ...params,
                        type: 'custom',
                        markerEnd: {
                            type: MarkerType.Arrow,
                            width: 20,
                            height: 20,
                            color: '#000',
                        },
                    },
                    eds
                )
            );
        },
        [nodes, edges, setEdges]
    );

    useEffect(() => {
        if (!isConfigSaved || !configData.length) return;

        let hasChanges = false;
        const updatedNodes = nodes.map((node) => {
            const matchingConfig = configData.find(conf => conf.nodeId === node.data.nodeId);
            hasChanges = true;
            if (matchingConfig) {
                const hasTypeRef = node.data.hasTypeRef || node.data.outputs.some((output: any) => output.config?.file_type_ref);
                const updatedOutputs = node.data.outputs.map((output: any) => {
                    if (output.config?.file_type_ref?.ref_field === "file_type" || hasTypeRef) {
                        const fileTypeValue = matchingConfig.schema?.properties?.file_type?.default || "default_value";
                        return {
                            ...output,
                            config: {
                                file_type: {
                                    file_type_id: fileTypeValue,
                                    multiple: output?.config?.file_type_ref?.multiple || null
                                }
                            }
                        };
                    }
                    return output;
                });


                return {
                    ...node,
                    data: {
                        ...node.data,
                        hasTypeRef: hasTypeRef,
                        outputs: updatedOutputs,
                        configSchema: {
                            ...node.data.configSchema,
                            properties: {
                                ...node.data.configSchema.properties,
                                ...matchingConfig.schema.properties
                            }
                        }
                    }
                };
            }

            return node;
        });

        if (hasChanges) {
            setNodes(updatedNodes);
            notifySucess("Plugin configuration updated successfully");
        }
        setIsConfigSaved(false);
    }, [configData, isConfigSaved, nodes]);


    const [{ canDrop, isOver }, drop] = useDrop({
        accept: ItemTypes.NODE,
        drop: (item: any, monitor) => {
            console.log("iiii", item)
            const offset = monitor.getClientOffset();
            const dropTarget = flowWrapper.current?.getBoundingClientRect();
            const position = offset && dropTarget ? { x: offset.x - dropTarget.left, y: offset.y - dropTarget.top } : { x: 0, y: 0 };
            let newNode: any;
            const hasConfigSchema = !!item.configSchema;
            const newNodeId = getId(item.name, nodes);
            newNode = {
                id: newNodeId,
                type: 'custom',
                position,
                data: {
                    id: item.id,
                    nodeId: newNodeId,
                    name: `${item.name}`,
                    hasConfig: hasConfigSchema,
                    inputLimit: item.inputs.length,
                    outputLimit: item.outputs.length,
                    inputs: item.inputs,
                    outputs: item.outputs,
                    description: item.description,
                    configSchema: hasConfigSchema ? item.configSchema : undefined,
                    isSelected: true,
                },
            };
            setConfigData([{ nodeId: newNode.data.nodeId, nodeName: item.id, schema: item.configSchema }])
            setNodes((prevNodes) =>
                prevNodes.map((node) => ({
                    ...node,
                    data: { ...node.data, isSelected: false } // Deselect all existing nodes
                })).concat(newNode) // Add the new node which is selected
            );

            // Add click event listener to the new node
            setTimeout(() => {
                const newNodeElement = document.querySelector(`[data-id="${newNode.id}"]`);
                if (newNodeElement) {
                    newNodeElement.addEventListener('click', () => {

                        // Set the selected node ID and update configuration data
                        setConfigData([{
                            nodeId: newNode.data.nodeId,
                            nodeName: newNode.data.id,
                            schema: newNode.data.configSchema
                        }]);

                        // Ensure that the clicked node is selected, and others are deselected
                        setNodes((prevNodes) =>
                            prevNodes.map((node) =>
                                node.id === newNode.id
                                    ? { ...node, data: { ...node.data, isSelected: true } } // Select clicked node
                                    : { ...node, data: { ...node.data, isSelected: false } } // Deselect others
                            )
                        );
                    });
                }
            }, 100); // Delay to ensure the node is rendered

        },
        collect: (monitor) => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop(),
        }),
    });

    const convertToObject = (data: any): Record<string, any> => {
        if (Object.keys(data).length === 0) {
            return {};
        }
        const { properties } = data;
        const defaultValues: Record<string, any> = {};
        Object.keys(properties).forEach(key => {
            const property = properties[key];
            if (property.default !== undefined) {
                defaultValues[key] = property.default;
            }
        });

        return defaultValues;
    };
    const transformData = (flowData: { edges: any[]; nodes: any[]; }) => {
        const edges = flowData.edges.map((edge: { source: any; target: any; sourceHandle: string; targetHandle: string; }) => {
            const sourceNode = flowData.nodes.find((node: { id: any; }) => node.id === edge.source);
            const targetNode = flowData.nodes.find((node: { id: any; }) => node.id === edge.target)
            return {
                from_file_id: edge.sourceHandle.split("-")[1],
                from_node: sourceNode?.id,
                to_file_id: edge.targetHandle.split("-")[1],
                to_node: targetNode?.id,
            };
        });
        const plugins = flowData.nodes.map((node: { id: string; data: any; }) => ({
            id: node.id,
            plugin_id: node.data.id,
            config: convertToObject(node.data.configSchema)
        }));
        return {
            edges,
            plugins,
            ...wftInfo
        };
    };

    const handleSave = () => {
        const flowData = {
            nodes,
            edges,

        };
        const convertedData = transformData(flowData);

        WorkflowTemplatesService.createWorkflowTemplate(convertedData)
            .then((res) => {
                setNodes([]);
                setEdges([]);
                setConfigData([]);
                setWftInfo({ name: "", description: "" });
                notifySucess("Workflow template created successfully.");
            })
            .catch((err) => {
                console.table(err)
                notifyError("Incompatible plugin file connection !")
            })
    };

    const toggleAddInfoModal = (flag: boolean) => {
        setAddInfoModalVisible(flag);
    };

    const handleBasicIfoFormSubmit = (ev: React.FormEvent) => {
        ev.preventDefault();
        toggleAddInfoModal(false);
    };

    return (
        <div style={{ height: "100%", }}>
            <div ref={flowWrapper} className="flow-background" style={{ height: '95%', width: "100%", position: 'relative', border: "1px silid green" }}>
                <div ref={drop} style={{ height: '100%' }}>
                    <ReactFlow
                        nodes={nodes}
                        edges={edges}
                        onNodesChange={onNodesChange}
                        onEdgesChange={onEdgesChange}
                        onConnect={onConnect}
                        nodeTypes={nodeTypes}
                        edgeTypes={edgeTypes}
                    >
                        <MiniMap />
                        <Controls style={{ display: "flex", flexDirection: "column-reverse" }}>
                        </Controls>
                        <Background gap={16} size={1} color="#ddd" />
                    </ReactFlow>
                    <div>
                        <Button icon={<DocumentAddRegular />}
                            onClick={() => { toggleAddInfoModal(true) }}
                        >Add Info</Button>
                        <Button
                            disabled={!wftInfo.name || !wftInfo.description}
                            onClick={handleSave} style={{ marginLeft: "1em" }} appearance="primary" icon={<SaveRegular />}>
                            Save
                        </Button>
                    </div>

                </div>
                <Toaster toasterId={toasterId} position="bottom" limit={1} />
                <AddInfo
                    visible={addInfoModalVisible}
                    toggleModal={toggleAddInfoModal}
                    wftInfo={wftInfo}
                    setWftInfo={setWftInfo}
                    handleSubmit={handleBasicIfoFormSubmit}
                />
            </div>
        </div>
    );
}

function FlowWithProvider() {
    return (
        <ReactFlowProvider>
            <Flow />
        </ReactFlowProvider>
    );
}

export default FlowWithProvider;
