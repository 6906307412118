import { Button, makeStyles, shorthands } from '@fluentui/react-components';
import React from 'react'
import BrandName from '../../../common/BrandName';
import HomeBG from "../../../../assets/icons/landing/homeBGMobile.webp";
import MobileHeader from '../../common/MobileHeader';
import processPoster from "../../../../assets/icons/landing/processPoster.svg"
import { flyghtCloudData } from '../../common/FlyghtCloudData';
import { DataStrategy } from '../../common/DataStrategyData';
import { useNavigate } from 'react-router-dom';

interface flyghtCloudDataProps {
    id: number,
    title: string,
    icon: string,
    info: string
}
interface CarouselDataProps {
    id: number,
    title: string,
    icon: string,
    info: string,
    tags: string[]
}

function MobileHome() {
    const classes = useStyles();
    const navigate = useNavigate();

    const [flyghtCloudDatas, setFlyghtCloudDatasProps] = React.useState<flyghtCloudDataProps[] | null>(null);
    const [dataStrategyDatas, setDataStrategyDatas] = React.useState<CarouselDataProps[] | null>(null);

    React.useEffect(() => {
        setFlyghtCloudDatasProps(flyghtCloudData);
        setDataStrategyDatas(DataStrategy);
    }, []);

    return (
        <>
            <div className={classes.container}>
                <MobileHeader />
                <div className={classes['mob-container']}>
                    <div style={{ color: "white" }}>
                        <h1 className={classes.barndingLabel}>FLYGHT</h1>
                        <h1 className={classes.barndingLabel} style={{
                            background: "linear-gradient(90deg, #AAD824 0%, #2ABCD0 100%)",
                            backgroundClip: "text",
                            color: "transparent",
                            display: "inline-block",
                            WebkitBackgroundClip: "text",
                            WebkitTextFillColor: "transparent"
                        }}>CLOUD</h1>

                        <h3 className={classes.barndingInfo} style={{ margin: "1em 0", }}>Enhance your drone data’s workflow and unlock unparalleled insights across your asset lifecycle with <BrandName />. </h3>
                        <div className={classes.commonRow} style={{ marginTop: "4em" }}>
                            <h3 className={classes.initialHeading}>One platform,<br /> infinite possibilities.</h3>
                            <h3 className={classes.barndingInfo}> Unlock the full potential of drone data intelligence.</h3>
                            <Button shape="circular" className={classes.gradBtn}
                                onClick={() => {
                                    navigate(`/book-demo`);
                                }}
                                style={{ background: "linear-gradient(90deg, #AAD824 0%, #2ABCD0 100%)", color: "white", border: "none" }}
                            >Connect to Expert</Button>
                        </div>
                        <div className={classes.commonRow}>
                            <h3 className={classes.initialHeading}>Join The Flyght Cloud <br /> Ecosystem as a Partner</h3>
                            <h3 className={classes.barndingInfo}>Integrate your tools and offer enhanced solutions to Flyght Cloud users</h3>
                            <Button shape="circular" className={classes.gradBtn}
                                onClick={() => {
                                    navigate(`/mob-becomeProvider`);
                                }}
                                style={{
                                    backgroundColor: "white",
                                    color: "transparent",
                                    backgroundImage: "linear-gradient(90deg, #AAD824 0%, #2ABCD0 100%)",
                                    WebkitBackgroundClip: "text",
                                    backgroundClip: "text",
                                }}
                            > Become a Provider</Button>
                        </div>
                    </div>
                </div>
            </div>
            <div className={classes['secondary-container']}>
                <div style={{ textAlign: "center", color: "#08201D" }}>
                    <h3 className={classes.ssinitialHeading} >One platform, infinite possibilities.</h3>
                    <h3 className={classes.ssbarndingInfo}>Unlock the full potential of drone data intelligence.</h3>
                    <img src={processPoster} alt='processPoster' style={{ marginTop: "2em" }} />
                </div>
                <div className={classes.commonRow}>
                    <h2 className={classes.primaryHeading}>Why choose <span> FLYGHT CLOUD?</span></h2>
                    {flyghtCloudDatas && (
                        <div>
                            {flyghtCloudDatas.map((data, index) => (
                                <div key={index} style={{ display: "flex", marginTop: ".3em", alignItems: "center" }}>
                                    <div style={{ width: "10%" }}>
                                        <img src={data.icon} alt={data.title} />
                                    </div>
                                    <div style={{ width: "80%", paddingLeft: "1em" }}>
                                        <h3 className={classes.dataTitle}>{data.title}</h3>
                                    </div>
                                </div>
                            ))}
                        </div>
                    )}
                </div>
                <div className={classes.commonRow}>
                    <h2 className={classes.primaryHeading}>Elevate your data strategy with <span>tailored solution </span></h2>
                    {dataStrategyDatas && (
                        <div style={{ display: "flex", justifyContent: "space-between", flexWrap: "wrap", marginTop: ".3em", alignItems: "center" }}>
                            {dataStrategyDatas.map((data, index) => (
                                <div key={index} style={{ marginTop: "1em", display: "flex", justifyContent: "center", alignItems: "center", width: "50%", height: "10vh" }} >
                                    <img src={data.icon} alt={data.title} />
                                </div>
                            ))}
                        </div>
                    )}
                </div>
                <div className={classes.commonRow}>
                    <h2 className={classes.primaryHeading}>Ready to take <span>flight?</span></h2>
                    <h3 className={classes.ssbarndingInfo}>Sign in to your FlyghtCloud account or book a demo to experience the future of drone data analytics. </h3>
                    <h3 className={classes.ssbarndingInfo} style={{ marginTop: "2em" }}>Whether you're a seasoned professional or just starting your journey with drone data, FlyghtCloud has the tools and expertise to support your success.</h3>
                </div>
                <div className={classes.commonRow} style={{ fontSize: ".7rem", textAlign: "center" }}>
                    <p style={{ marginTop: "1em", color: "#586A84" }}>Copyright © 2024 ideaForge Technology Ltd. All rights reserved.</p>
                </div>
            </div >
        </>
    )
}

export default MobileHome;

const useStyles = makeStyles({
    "container": {
        minHeight: "90vh",
        maxHeight: "100vh",
        minWidth: "100vw",
        backgroundImage: `url(${HomeBG})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundPositionX: "40%",
        backgroundPositionY: "40%",
        position: "relative",
    },
    barndingLabel: {
        fontSize: "3rem",
        lineHeight: "3rem",
        fontWeight: "700"
    },
    "mob-container": {
        width: "93%",
        margin: "2em auto 1em auto"
    },
    "secondary-container": {
        width: "93%",
        margin: "1em auto"
    },
    barndingInfo: {
        fontSize: ".85rem",
        lineHeight: "1.1rem",
        color: "#ffff",
        marginTop: ".5em"
    },
    ssbarndingInfo: {
        fontSize: ".85rem",
        lineHeight: "1.1rem",
        color: "#08201D",
    },
    dataTitle: {
        fontSize: ".85rem",
        fontWeight: "600",
        lineHeight: "1.1rem",
        color: "#08201D",
    },
    initialHeading: {
        fontSize: "1.8rem",
        fontWeight: "500",
        lineHeight: "2rem",
    },
    ssinitialHeading: {
        fontSize: "1.3rem",
        fontWeight: "500",
        lineHeight: "2rem",
    },
    commonRow: {
        marginTop: "2em",
        marginBottom: "2em",
    },
    gradBtn: {
        ...shorthands.padding("10px"),
        marginTop: "1em",
        marginBottom: "1em",
        fontWeight: "1rem"
    },
    primaryHeading: {
        fontSize: "1.3rem",
        color: "#000000",
        paddingBottom: ".5em",
        lineHeight: "1.5rem",
        fontWeight: 600,
        "> span": {
            color: "#95C114",
        },
    },

})