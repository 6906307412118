import React from 'react'
import { makeStyles } from '@fluentui/react-components'

function AskAdministrator() {
    const classes = useStyles();

    return (
        <div className={classes.orgMessageContainer}>
            You have not been added to any organisation, Please contact your administrator
        </div>
    )
}

export default AskAdministrator

const useStyles = makeStyles({
    orgMessageContainer: {
        display: "flex",
        alignItems: "center",
        height: "80%",
        justifyContent: "center",
        textAlign: "center",
        color: "#586A84",
        fontSize: "20px",
        fontWeight: 600
    },
})