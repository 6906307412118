import { Button, Menu, MenuItem, MenuList, MenuPopover, MenuTrigger, makeStyles, shorthands } from "@fluentui/react-components";
import { useEffect, useState } from "react";
import DeleteIcon from "../../assets/icons/delete.svg";
import FilterIcon from "../../assets/icons/filter.svg";
import { DashboardResponse, FlightResponse, OrganisationsService, TimeRange } from "../../services/openapi";
import { IconButton } from "../IconButton";
import NoFlightsState from "./NoFlightsState";
import RecentFlightsColumnHeader from "./RecentFlightColumnHeader";
import RecentFlightRows from "./RecentFlightRows";
import RowSeperator, { SeperatorTypes } from "./RowSeperator";
import DeleteFlightModal from "./modals/DeleteFlightModal";

interface Props {
    dashboardInfo: DashboardResponse;
    filterDashboardResponse: (t: TimeRange) => void
    topOffset: string;
    currentFilter?: TimeRange,
    clearFilter: () => void,
    isOrgInactiveState: boolean | null
}

export default function RecentFlightsCard({ dashboardInfo, topOffset, filterDashboardResponse, currentFilter, clearFilter, isOrgInactiveState }: Props) {
    const classes = useStyles()
    const [selectedFlights, setSelectedFlights] = useState<FlightResponse[]>([])
    const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false)
    const [showOrgMessage, setShowOrgMessage] = useState(false)

    const handleFlightRowClick = (flight: FlightResponse) => () => {
        setSelectedFlights(prev => {
            const found = prev.some(el => el.id === flight.id);
            if (!found) {
                return [...prev, flight]
            } else {
                return prev.filter(el => el.id !== flight.id)
            }
        })
    }

    const getUserOrganisation = () => {
        OrganisationsService.getMyOrganisations().then((data) => {
            setShowOrgMessage(data.organisations.length === 0);
        }).catch((err) => console.error("Error getting user organisation", err));
    };

    useEffect(() => {
        getUserOrganisation();
    }, []);


    const toggleDeleteDialog = (flag: boolean) => {
        setShowDeleteDialog(flag)
    }

    const ongoingFlights = dashboardInfo.recentFlights.filter(f => f.numOngoingWorkflows !== null && f.numOngoingWorkflows !== undefined && f.numOngoingWorkflows > 0)
    const completedFlights = dashboardInfo.recentFlights.filter(f => {
        if (f.numCompletedWorkflows === null || f.numCompletedWorkflows === undefined) {
            return false
        }
        if (f.numCompletedWorkflows > 0 || (f.numCompletedWorkflows === 0 && f.numOngoingWorkflows === 0)) {
            return true
        }
        return false
    })

    return <div className={classes.container} style={{ maxHeight: `calc(100vh - ${topOffset})` }}>
        {
            !isOrgInactiveState && (
                <div className={classes.inActive}
                    style={{
                        background: "linear-gradient(90deg, #FF8C8C 0%, #FD7D46 100%)"
                    }}
                >Organisation Inactive</div>
            )
        }
        <div style={{ opacity: isOrgInactiveState ? 1 : 0.5, }}>
            <div className={classes.cardHeaderContainer}>
                <div className={classes.cardHeaderContent}>
                    <h2 className={classes.cardHeading}>Recent Flights</h2>

                    <div className={classes.cardHeaderButtonsContainer}>
                        {currentFilter && <button className={classes.roundedButton} onClick={clearFilter}>Clear Filter</button>}

                        <IconButton
                            icon={<img src={DeleteIcon} alt="icon" className={classes.iconImage} />}
                            disabled={selectedFlights.length === 0}
                            onClick={() => toggleDeleteDialog(true)}
                            borderColor='#7D8FA9'
                            label="Delete"
                        />

                        <Menu>
                            <MenuTrigger disableButtonEnhancement>
                                <Button
                                    icon={<img src={FilterIcon} alt="icon" className={classes.iconImage} />}
                                    size="large"
                                    className={classes.filterButton}>
                                    <p className={classes.filterButtonText}>{currentFilter ? currentFilter : "Filter"}</p>
                                </Button>
                            </MenuTrigger>

                            <MenuPopover>
                                <MenuList>
                                    <MenuItem onClick={() => {
                                        filterDashboardResponse(TimeRange.PAST_WEEK)
                                    }}>
                                        <p className={classes.menuText}>
                                            Past Week
                                        </p>
                                    </MenuItem>
                                    <MenuItem onClick={() => {
                                        filterDashboardResponse(TimeRange.PAST_MONTH)
                                    }}>
                                        <p className={classes.menuText}>
                                            Past Month
                                        </p></MenuItem>
                                </MenuList>
                            </MenuPopover>
                        </Menu>

                    </div>
                </div>

                <RecentFlightsColumnHeader setSelectedFlights={setSelectedFlights} flights={dashboardInfo.recentFlights} selectedFlights={selectedFlights} />
            </div>

            <div className={classes.rowContainer}>
                {showOrgMessage ? <div className={classes.orgMessageContainer}>You have not been added to any organisation, Please contact your administrator</div> :
                    <>
                        {ongoingFlights.length > 0 && (
                            <>
                                <RowSeperator type={SeperatorTypes.success} text="Workflow Ongoing" />

                                <RecentFlightRows flights={ongoingFlights} handleFlightRowClick={handleFlightRowClick} selectedFlightIDs={selectedFlights.map(f => f.id)} />
                            </>
                        )}

                        {completedFlights.length > 0 && (
                            <>
                                <RowSeperator type={SeperatorTypes.warning} text="Workflow Completed" />

                                <RecentFlightRows flights={completedFlights} handleFlightRowClick={handleFlightRowClick} selectedFlightIDs={selectedFlights.map(f => f.id)} />
                            </>
                        )}


                        {(ongoingFlights.length === 0 && completedFlights.length === 0) && <NoFlightsState />}
                    </>}
            </div>
        </div>
        <DeleteFlightModal visible={showDeleteDialog} toggleModal={toggleDeleteDialog} selectedFlights={selectedFlights} />
    </div>
}

const useStyles = makeStyles({
    container: {
        ...shorthands.borderRadius("1rem"),
        ...shorthands.border("1px", "solid", "#E3E9F2"),
        display: "flex",
        flexDirection: "column",
        width: "74%",
        position: "relative",
        overflow: "auto"
    },
    cardHeaderContainer: {
        paddingBottom: "18px",
        paddingRight: "32px",
        paddingTop: "32px",
        paddingLeft: "32px",
        boxShadow: "0px 2px 10px 0px rgba(0, 0, 0, 0.07)",
        borderTopLeftRadius: "1rem",
        borderTopRightRadius: "1rem",
        backgroundColor: "white",
    },
    rowContainer: {
        paddingTop: "0px",
        paddingLeft: "32px",
        paddingBottom: "26px",
        maxHeight: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        overflowY: "auto",
    },
    cardHeaderContent: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    },
    cardHeading: {
        color: "#043957",
        fontSize: "28px",
        fontWeight: 600,
        lineHeight: "36px",
    },
    cardHeaderButtonsContainer: {
        display: "flex",
        ...shorthands.gap("1rem"),
        alignItems: "center",
    },
    roundedButton: {
        ...shorthands.outline("none"),
        ...shorthands.border("none"),
        backgroundColor: "transparent",
        color: "#5E5CE6",
        fontSize: "18px",
        cursor: "pointer",
        paddingTop: "5px",
        paddingBottom: "5px",
        paddingLeft: "20px",
        paddingRight: "20px",
        ...shorthands.borderRadius("1rem"),
        ":hover": {
            backgroundColor: "#D8D8FF",
            ...shorthands.padding("5px 20px"),
        }
    },
    iconImage: {
        minWidth: "18px",
        minHeight: "18px",
    },
    filterButton: {
        ...shorthands.border("1px solid #7D8FA9"),
        ...shorthands.padding("6px 12px"),
        ":hover": {
            backgroundColor: "#E5E5FE",
        }
    },
    filterButtonText: {
        fontSize: "14px",
        color: "#7D8FA9",
        fontWeight: 400,
    },
    menuText: {
        fontSize: "14px",
    },
    orgMessageContainer: {
        display: "flex",
        alignItems: "center",
        paddingTop: "5rem",
        paddingBottom: "5rem",
        justifyContent: "center",
        textAlign: "center",
        height: "100%",
        color: "#586A84",
        fontSize: "20px",
        fontWeight: 600
    },
    inActive: {
        position: "absolute",
        top: 0,
        left: 0,
        opacity: 2,
        zIndex: 1000,
        textAlign: "center",
        padding: ".2em 3em",
        color: "white",
    }
})


